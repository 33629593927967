/* global React, G */
import { Children, cloneElement, Suspense, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import {
  ArrowForward as ArrowForwardIcon,
  CloudDownload as CloudDownloadIcon,
  Download as DownloadIcon,
  Favorite as FavoriteIcon,
} from '@mui/icons-material'
import { formatFilesize } from 'lib/util/attachment'
import { preload, useStyles } from '@platform/react/hook'
import MuiListTemplateButton from 'ui/Component/Attachment/List/Template/Button'
import SvgIcon, { SvgRoundedIcon } from 'ui/Element/Icon/Svg'
import ErrorBoundary from 'ui/Error'
import ScrollGrid from 'ui/Component/Grid/ScrollGrid'
import { PlatformEvent } from 'lib/util'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import ApplicationContext from 'platform/react/context/application'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  progress: {
    maxWidth: '100%',
    height: '3rem',
    marginBottom: '-3.5rem',
    borderRadius: '0.5rem',
    backgroundColor: 'transparent',
    '& > *': {
      backgroundColor: theme.palette.background.light,
    },
  },
  folderTitle: {
    maxWidth: 'calc(100% - 3rem)',
    color: theme.palette.black.main,
  },
  scrollContainer: {
    height: 'calc(100% - 6.5rem)',
  },
  scrollContent: {
    flexDirection: 'column',
    gap: '1rem',
  },
  folderList: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0.5rem',
    backgroundColor: theme.palette.white.main,
    borderRadius: '0.5rem',
  },
  skipTitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
  },
  divider: {
    borderColor: `${theme.palette.gray['900']}!important`,
  },
  folderRow: {
    cursor: 'pointer',
    padding: '0.75rem',
    display: 'flex',
    maxWidth: '100%',
    minHeight: '3rem',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.black.main,
    '&:first-child': {
      marginTop: '0!important',
    },
    '&:last-child': {
      marginBottom: '0!important',
    },
    '& > *': {
      zIndex: 10, // text should be above progress bar
    },
    '&:hover': {
      borderRadius: '0.5rem',
      backgroundColor: theme.palette.background.light,
    },
  },
  icon: {
    cursor: 'pointer',
    '& .MuiButton-root': {
      padding: 0,
      width: 24,
      minWidth: 24, // defaults to 64 with MuiButton
      height: 24,
      minHeight: 24, // defaults to 40 with MuiButton
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
  },
  breadcrumbs: {
    marginBottom: '1rem',
  },
  header: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 25,
    alignItems: 'center',
    // without this, the breadcrumbs will shift down
    // if we display an icon due to alignItems: 'center'
    minHeight: 36,
  },
  title: {
    width: '100%',
    display: 'flex',
    maxWidth: 'calc(100% - 1rem)',
    flexDirection: 'row',
    gap: '0.75rem',
    alignItems: 'center',
    '& > div:first-child': {
      '& > *': { // rotating arrow icon
        transform: 'rotate(180deg)',
        cursor: 'pointer',
      },
    },
  },
  document: {
    height: 500,
    display: 'flex',
    color: theme.palette.black.main,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  documentIcon: {
    fontSize: 80,
    marginBottom: 30,
  },
  documentLabel: {
    fontWeight: 700,
    marginBottom: 12,
  },
  documentText: {
    marginBottom: 27,
  },
  downloadButton: {
    '& .MuiButton-root': {
      borderRadius: 8,
      boxShadow: 'none',
    },
  },
  downloadSize: {
    color: theme.palette.text.description,
  },
  ...theme.custom.fileFolder,
})

/**
 * Document Information
 *
 * Component to render general information about the document.
 *
 * @param {Object} node               the current node to render
 * @param {Object} attachment         the attachment to download
 * @param {String} name               name of the node
 * @param {String} label              label for the download button
 * @param {Object} options            additional props
 * @returns {JSX.Element}
 * @constructor
 */
const DocumentationInformation = ({ node, attachment, name, ...options }) => {
  const { classes, labels } = options
  const { label, text, noData } = labels

  const { session } = useContext(ApplicationContext)
  const { title: actionTitle = name } = session[G.MODULE][G.STATE][G.ACTION][G.UI]

  const title = node?.$children?.length ? name : actionTitle
  const description = node?.$children?.length ? text : noData

  const downloadIcon = options.downloadIcon
    ? <SvgIcon
      width={20}
      height={20}
      raw={false}
      color={'common.white'}
      icon={options.downloadIcon.name}
      variant={options.downloadIcon.variant}
    />
    : <DownloadIcon/>

  const documentIcon = options.documentIcon
    ? <SvgIcon
      width={80}
      height={80}
      raw={false}
      icon={options.documentIcon.name}
      variant={options.documentIcon.variant}
    />
    : <CloudDownloadIcon />

  // In case the node doesn't have a type, we haven't fetched anything yet. Don't display
  // anything in that case
  return !node?.type ? null : <Grid
    item
    container
    className={classes.document}
    xs={12}
    sm={12}
    md={12}
    lg={12}
    xl={12}
  >
    <span className={classes.documentIcon}>
      {documentIcon}
    </span>
    <Typography
      className={classes.documentLabel}
      variant={'subtitle2'}>
      {title}
    </Typography>
    <Typography
      className={classes.documentText}
      variant={'body1'}>
      {description}
    </Typography>
    {attachment && ( // TODO: This check shouldn't be necessary after SP-778
      <>
        <Box
          sx={{ mb: '12px' }}
          className={classes.downloadButton}
        >
          <MuiListTemplateButton
            value={label}
            fullWidth={false}
            variant={'contained'}
            endIcon={downloadIcon}
            attachment={attachment}
          />
        </Box>
        <Typography
          variant={'body1'}
          className={classes.downloadSize}
        >
          {formatFilesize(attachment.value.size)}
        </Typography>
      </>
    )}
  </Grid>
}

/**
 * Title Component
 *
 * Renders the title of the current node, together with a back button and an optional
 * download button
 *
 * @param {Object} node               the node to render
 * @param {string} title              the title of the node
 * @param {string} type               the type of the node
 * @param {Object} attachment         optional attachment of the node
 * @param {Object} classes            classes for stylling
 * @param {Object} events             events for the component
 * @param {Function} events.onClick   handler for clicking on the back button
 * @param {Object} options            additional props
 * @returns {JSX.Element}
 * @constructor
 */
const Title = ({ node, title, type, attachment, classes, events, ...options }) => {
  const { onClick, onFavoriteClick = null, onFavoriteOpen = null } = events
  const { showFavorite, icons } = options
  const theme = useTheme()

  // Disabled for now, see NOTE in SP-1505
  // const showFavoriteIcon = showFavorite && type === 'File'

  // Disabled for now, see NOTE in SP-1505
  // const isFavorite = !showFavoriteIcon
  //   ? false
  //   : onFavoriteOpen?.(new PlatformEvent('open', { value: node?.documentationId || node?.attachmentId }))

  // Disabled for now, see NOTE in SP-1505
  // const favoriteEvent = event => new PlatformEvent(event, {
  //   payload: node?.documentationId || node?.attachmentId,
  //   options: {
  //     type: 'documentation',
  //     delete: isFavorite,
  //   },
  // })

  // Disabled for now, see NOTE in SP-1505
  // const favoriteIcon = icons.favorite
  //   ? <SvgIcon
  //     width={24}
  //     height={24}
  //     raw={false}
  //     rounded={true}
  //     padding={8}
  //     background={theme.palette.common.white}
  //     color={theme.palette.error[700]}
  //     icon={icons.favorite.name}
  //     variant={icons.favorite.variant}
  //   />
  //   : <FavoriteIcon/>

  // Disabled for now, see NOTE in SP-1505
  // const noFavoriteIcon = icons.noFavorite
  //   ? <SvgIcon
  //     width={24}
  //     height={24}
  //     raw={false}
  //     rounded={true}
  //     padding={8}
  //     background={theme.palette.common.white}
  //     color={theme.palette.common.black}
  //     icon={icons.noFavorite.name}
  //     variant={icons.noFavorite.variant}
  //   />
  //   : <FavoriteIcon/>

  return (
    <Grid
      container
      className={classes.header}
    >
      <Grid
        item
        className={classes.title}
      >
        {node?.parent?.id && (
          <Box
            onClick={() => onClick(node.parent.id)}
          >
            <SvgIcon
              padding={8}
              rounded={true}
              width={'1.5rem'}
              height={'1.5rem'}
              variant={'filled'}
              icon={'arrow_right'}
              background={theme.palette.common.white}
              color={theme.palette.common.black}
            />
          </Box>
        )}
        <OverflowTooltip variant={'24/bold'}>
          {title}
        </OverflowTooltip>
        {/* Disabled for now, see NOTE in SP-1505 */}
        {/* {showFavoriteIcon && ( */}
        {/*  <Box */}
        {/*    sx={{ cursor: 'pointer' }} */}
        {/*    onClick={e => onFavoriteClick?.(favoriteEvent(e))} */}
        {/*  > */}
        {/*    {isFavorite ? favoriteIcon : noFavoriteIcon} */}
        {/*  </Box> */}
        {/* )} */}
      </Grid>
    </Grid>
  )
}

/**
 * Directory Component
 *
 * Responsible for displaying a directory and its content.
 *
 * @param {Object} node               the directory to render
 * @param {Object} classes            styling for the component
 * @param {Object} events             events for the directory
 * @param {Object} labels             labels for the directory
 * @param {Object} icons              icons for the directory
 * @param {React.ReactNode} ListItem  template to use for a single directory child
 * @param {Object} options            additional props
 * @returns {JSX.Element}
 * @constructor
 */
const Directory = ({ node, classes, events, labels, icons, ListItem, ...options }) => {
  const theme = useTheme()
  const { onClick, onSkip, onNodeType, onNodeLabel } = events
  const ref = useRef(null)

  const skipIcon = icons.skipIcon
    ? <SvgIcon
      raw={false}
      icon={icons.skipIcon.name}
      variant={icons.skipIcon.variant}
      color={theme.palette.gray['500']}
    />
    : <ArrowForwardIcon/>

  return (
    <ScrollGrid
      ref={ref}
      container
      component={'main'}
      justifyContent={'flex-start'}
      classes={{ root: classes.scrollContainer, content: classes.scrollContent }}
    >
      {node?.skippable && (
        <Grid
          container
          onClick={onSkip}
          sx={{ cursor: 'pointer' }}
          className={classes.folderList}
        >
          <Grid
            container
            className={classes.folderRow}
          >
            <Grid
              item
              className={classes.skipTitle}
            >
              {skipIcon}
              <OverflowTooltip variant={'14/medium'}>
                {labels.skipLabel}
              </OverflowTooltip>
            </Grid>
            <Grid
              item
              className={classes.icon}
            >
              <SvgIcon
                raw={false}
                icon={icons.forwardIcon.name}
                variant={icons.forwardIcon.variant}
                color={theme.palette.gray['500']}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Box className={classes.folderList}>
        {node?.$children?.length && node.$children.map((item, index) => (
          <ListItem
            key={index}
            node={item}
            types={options.types}
            fileIcons={options.fileIcons}
            header={item?.header || null}
            footer={item?.footer || null}
            lastItem={index === node.$children.length - 1}
            events={{ onClick, onNodeType, onNodeLabel }}
            attachment={item.attachment ? {
              ...item.attachment,
              key: item.attachmentId,
              uuid: item.id,
            } : null}
            {...{
              downloadIcon: icons.downloadIcon,
              forwardIcon: icons.forwardIcon,
              skipSingleNode: icons.skipSingleNode,
              notifyOnSkippable: icons.notifyOnSkippable,
              classes,
            }}
          />
        ))
        }
      </Box>
    </ScrollGrid>
  )
}

/**
 * Render the contents of the current node.
 *
 * @param {Object} node                   the current node to render.
 * @param {Object} events                 events passed from parent component
 * @param {Function} events.onAttachment  handler responsible for doing something with a single
 *                                        attachment
 * @param {Object} classes                styles to apply
 * @param {React.ReactNode} ListItem      component to use for rendering sub folders
 * @param {Object} children               children to render
 * @param {Object} options                options passed from parent component
 * @returns {JSX.Element}
 * @private
 */
const _content = (node, events, { classes, listItem: ListItem, children, ...options }) => {
  const attachment = (node.attachment && {
    ...node.attachment,
    key: node.attachmentId,
    documentationId: node?.documentationId,
  })
      || (node.url && {
        value: {
          name: node?.realName || node.url?.split('/')?.at(-1) || node?.name,
          url: node.url,
          documentationId: node?.documentationId,
        },
      })
      || null
  const { onNodeType, onAttachment, onBreadcrumbs, onNodeLabel = null } = events

  const handleClick = (id) => {
    const clickEvent = new PlatformEvent('click', { jump: true, id })
    events?.onClick?.(clickEvent)
  }

  const handleSkip = () => {
    const clickEvent = new PlatformEvent('click', { node })
    events?.onSkip?.(clickEvent)
  }

  const labelEvent = new PlatformEvent('label', { node })
  const nodeTitle = onNodeLabel?.(labelEvent) || node.name

  const nodeAttachment = attachment && onAttachment
    ? onAttachment?.(new PlatformEvent('open', { value: attachment }))
    : attachment

  const nodeType = onNodeType(node?.type)

  const displayTitle = (nodeType === 'Directory' && node?.$children?.length)
      || nodeType === 'File'
      || nodeType === 'Url'

  return (
    <>
      { // Optionally render breadcrumbs
        !options?.showBreadcrumbs ? null : (
          <Grid
            container
            className={classes.breadcrumbs}
          >
            {Children.map(children, child => (
              child.props.hidden || child.key !== 'breadcrumbs' ? null : cloneElement(child, {
                node,
                events: {
                  onOpen: onBreadcrumbs,
                  onClick: (e) => {
                    events?.onClick({ ...e, detail: { ...e.detail, jump: true } })
                  },
                },
              })
            ))}
          </Grid>
        )}
      { // Rendering the title
        // Only render if we don't render the root node, or, if it is the root node,
        // ensure that it has children, so no empty document
        displayTitle && (
          <Title
            node={node}
            type={nodeType}
            title={nodeTitle}
            classes={classes}
            attachment={nodeAttachment}
            events={{
              onClick: handleClick,
              onFavoriteOpen: events?.onFavoriteOpen,
              onFavoriteClick: events?.onFavoriteClick,
            }}
            showFavorite={options?.showFavorite}
            icons={{
              favorite: options?.favoriteIcon,
              noFavorite: options?.noFavoriteIcon,
            }}
          />
        )}
      { // Rendering a directory
        nodeType === 'Directory' && node?.$children?.length && (
          <Directory
            node={node}
            classes={classes}
            ListItem={ListItem}
            types={options.types}
            fileIcons={options.fileIcons}
            labels={{ skipLabel: options.skipLabel }}
            events={{
              onNodeType,
              onNodeLabel,
              onSkip: handleSkip,
              onClick: events?.onClick,
            }}
            icons={{
              skipIcon: options.skipIcon,
              forwardIcon: options.forwardIcon,
              downloadIcon: options.downloadIcon,
              skipSingleNode: options.skipSingleNode,
              notifyOnSkippable: options.notifyOnSkippable,
            }}
          />
        )}
      { // Rendering a file
        nodeType === 'File' && Children.map(children, child => (
          child.props.hidden || child.key !== 'file' ? null : cloneElement(child, {
            attachment: { ...nodeAttachment, uuid: `${node.id}-preview` },
            showToolbar: options?.showToolbar,
            showFavorite: options?.showFavorite,
            showFeedback: options?.showFeedback,
            maxPreviewSize: options?.maxPreviewSize,
            previewableTypes: options?.previewableTypes,
          })
        ))}
      { // Rendering information about the root node
        (nodeType === 'Root' || (nodeType === 'Directory' && !node?.$children?.length)) && (
          <DocumentationInformation
            node={node}
            name={node.name}
            attachment={nodeAttachment}
            labels={{
              downloadAll: options.downloadAllLabel,
              text: options.documentationInfo,
              noData: options.noDataLabel,
            }}
            {...{
              documentIcon: options.documentIcon,
              downloadIcon: options.downloadIcon,
              classes,
            }}
          />
        )}
    </>
  )
}

/**
 * Component to display the contents of a single node.
 *
 * Can be used on its own if {@param events.onClick} and {@param events.onOpen}
 * event handlers are present. If the latter is not, it expects {@param node}
 * from a parent component (e.g. {@link FileExplorer}).
 *
 * @param {Object} node               the current node to render.
 * @param {Object} events             events passed from parent component
 * @param {Function} events.onClick   handler for clicking on an item.
 * @param {Function} [events.onOpen]  optional onOpen handler.
 * @param {String} className          class to apply
 * @param {String} template           template to use for rendering children
 * @param {Object} children           children to render
 * @param {React.ReactNode} feedback  component for showing the feedback form
 * @param {Object} props              additional props from parent component
 * @param {React.Ref} ref             forwarded ref
 * @returns {JSX.Element}
 * @constructor
 */
const FileFolder = ({
  node,
  events,
  className,
  template,
  children,
  ...props
}, ref) => {
  const {
    spacing = 0,
    space = 0,
    gap = 0,
    group,
    types,
    showFeedback = false,
    showToolbar = false,
    showFavorite = false,
    ...restProps
  } = props

  const [currentNode, setCurrentNode] = useState(null)

  const ListItem = useMemo(() => preload(template), [template])
  const classes = useStyles(styles)()
  const theme = useTheme()

  const options = {
    ...restProps,
    group,
    classes,
    listItem: ListItem,
    children,
    showFeedback,
    showToolbar,
    showFavorite,
    types,
    theme,
  }

  // Node to display
  const targetNode = useMemo(() => currentNode || node || {}, [currentNode, node])

  // Set {@code currentNode} if onOpen event handler is present.
  useEffect(() => {
    (async () => {
      const newNode = await events?.onOpen?.(null)
      newNode && setCurrentNode(newNode)
    })()
  }, [])

  return (
    <ErrorBoundary>
      <Suspense fallback={null}>
        <Grid
          item
          ref={ref}
          className={`${classes.root} ${className}`}
          xs={props.xs}
          sm={props.sm}
          md={props.md}
          lg={props.lg}
          xl={props.xl}
          style={{
            padding: spacing
              ? theme.spacing(spacing)
              : theme.spacing(space, gap),
          }}
        >
          {_content(targetNode, events, options)}
        </Grid>
      </Suspense>
    </ErrorBoundary>
  )
}

export default FileFolder
