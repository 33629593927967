/* eslint-disable no-unused-vars */
import { Grid } from '@mui/material'
import ScrollGrid from 'ui/Component/Grid/ScrollGrid'
import { Thumbnail } from 'react-pdf'
import { useMemoRef, useStyles } from 'platform/react/hook'
import { useEffect, useRef, useState } from 'react'

const styles = (theme, { width }) => ({
  thumbnailContainer: {
    width,
    height: '100%',
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    borderRadius: '0.5rem',
    overflow: 'auto', // TODO: Make it work with ScrollGrid
  },
  thumbnailScrollContainer: {
    display: 'flex',
    gap: '1rem',
    margin: [['1rem', '1.5rem']],
    '& > *': {
      border: `1px solid ${theme.palette.text.secondary}`,
    },
  },
  thumbnail: {
    opacity: 0.4,
  },
  currentPage: {
    opacity: 1,
  },
})

/**
 * Simple Pdf Thumbnails Component
 *
 * Shows a container with all thumbnails of the current pdf.
 *
 * @param {Object} thumbnails                 props
 * @param {React.Ref} thumbnails.forwardedRef ref to the dom node
 * @param {Object} thumbnails.classes         styles from parent component
 * @param {string|number} thumbnails.width    width of the thumbnail container
 * @param {number} thumbnails.pages           number of pages
 * @param {Object} props                      additional props
 * @returns {JSX.Element}
 * @constructor
 */
const Thumbnails = ({ forwardedRef, classes: parentClasses, width, pages, currentPage, ...props }) => {
  const classes = { ...parentClasses, ...useStyles(styles, { width })() }

  const { loadedPages } = props

  const [thumbnailWidth, setThumbnailWidth] = useState(100)
  const containerRef = useRef(null)

  useEffect(() => {
    if (containerRef?.current) {
      // We need the content container of the ScrollGrid, in order to know its width, so we
      // can set the thumbnail width accordingly. It needs a fixed width in px, otherwise it
      // will default to what's defined in the pdf itself -> bad
      const thumbnailsContentContainer = containerRef
        .current.firstChild.firstChild.firstChild

      setThumbnailWidth(thumbnailsContentContainer.clientWidth)
    }
  }, [containerRef?.current])

  return (
    <Grid
      item
      ref={forwardedRef}
      className={classes.thumbnailContainer}
    >
      <ScrollGrid
        ref={containerRef}
        classes={{ content: classes.thumbnailScrollContainer }}
      >
        {loadedPages[currentPage - 1] && Array.from(new Array(pages), (el, i) => (
          <Thumbnail
            loading={null}
            pageNumber={i + 1}
            key={`thumbnail-${i}`}
            className={`${classes.thumbnail} ${currentPage === i + 1 ? classes.currentPage : ''}`}
            width={thumbnailWidth}
            inputRef={
              (currentPage === i + 1) || (currentPage === '' && i === 0)
                ? currentPageRef => currentPageRef && currentPageRef.scrollIntoView()
                : null
            }
          />
        ))}
      </ScrollGrid>
    </Grid>
  )
}

export default useMemoRef(Thumbnails, props => [
  props.width,
  props.pages,
  props.currentPage,
  props.loadedPages,
])
