/* eslint-disable object-curly-newline,no-undefined,no-nested-ternary */
import { useTheme } from '@mui/material'
import { isStr } from 'lib/util'
import { useMemoRef } from '@platform/react/hook'
import SimpleImage from 'ui/Element/Image/Simple'

/**
 * Avatar Image Presenter
 *
 * @param {Object} avatarProps
 * @param {Object} avatarProps.forwardedRef       a reference to the avatar element
 * @param {string|Object} avatarProps.fallback    fallback image or icon props to use
 * @param {Object} avatarProps.props              additional props
 * @param {Object} [avatarProps.props.attachment] attachment to fetch
 * @param {string} [avatarProps.props.value]      ref of the resource to fetch the avatar for
 * @param {Object} [avatarProps.props.api]        api information of the resource to fetch avatar
 * @returns {JSX.Element}                         the new avatar element
 * @constructor
 */
const AvatarImage = ({ forwardedRef, fallback = null, ...props }) => {
  const { attachment, value, api = { ref: 'avatar' } } = props

  const theme = useTheme()

  const {
    icon = 'avatar',
    variant = 'filled',
    color = theme.palette.white.main,
    background = theme.palette.gray[930],
  } = fallback || {}

  const isComplexFallback = fallback === null
      || fallback === undefined
      || !isStr(fallback)

  return (
    <SimpleImage
      api={api}
      value={value}
      ratio={'circular'}
      attachment={attachment}
      {...isComplexFallback
        ? { fallback: { icon, variant, color, background } }
        : { fallback }
      }
    />
  )
}

export default useMemoRef(AvatarImage, props => [props.value])
