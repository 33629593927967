/* global G */
import { forwardRef, useCallback, useContext } from 'react'
import AppContext from 'platform/react/context/application'
import Breadcrumbs from 'ui/Component/Group/Breadcrumbs'
import { useMemoRef } from 'platform/react/hook'
import { withStyles } from '@mui/styles'

const ForwardedRefBreadcrumbs = forwardRef(Breadcrumbs)

/**
 * Returns an object that specifies the visual appearance of the ActionBreadcrumbs component.
 *
 * @param {Object} theme  the application's theme object
 * @returns {Object}      elements' style definitions
 */
const style = theme => ({
  breadcrumbs: {
    '& li': {
      '&.MuiBreadcrumbs-separator': {
        color: theme.palette.gray[700],
      },
      '& span': {
        fontSize: '0.9rem',
        color: theme.palette.gray[400],
      },
      '&:not(:last-child) span, & div': {
        ...theme.typography['12/medium'],
        cursor: 'pointer',
        '&:hover': {
          color: theme.palette.black.main,
        },
      },
      '&:last-child span': {
        ...theme.typography['12/bold'],
        cursor: 'default',
      },
    },
  },
})

/**
 * Action Breadcrumbs layout component
 *
 * Intended to be used in the application's AppBar.
 *
 * Uses {@link Breadcrumbs} to display horizontally arranged links representing the user's current
 * and previous locations.
 *
 * @param {React.Ref} forwardedRef  a forwarded reference to the root element
 * @param {object} [classes]        the component's style classes
 * @param {boolean} [hidden]        whether the element should not be rendered
 * @return {false|JSX.Element}
 * @constructor
 */
const ActionBreadcrumbs = useMemoRef(({ forwardedRef, classes = {}, hidden }) => {
  const { session, router } = useContext(AppContext)
  const stack = session[G.PREV]
  const module = session[G.MODULE]
  const items = [
    ...stack.map(route => ({ name: route[G.CACHE].title?._cache })),
    { name: module[G.STATE][G.ACTION]?.[G.DATA].title?._cache },
  ]

  const handleClick = useCallback((event) => {
    const { index } = event.detail
    index < stack.length && router.go(index + 1)
  }, [stack])

  const getBreadcrumbs = () => items

  return hidden || items.length <= 1 ? null : (
    <ForwardedRefBreadcrumbs
      ref={forwardedRef}
      itemsAfter={2}
      itemsBefore={2}
      events={{
        onOpen: getBreadcrumbs,
        onClick: handleClick,
      }}
      classes={{
        root: classes.breadcrumbs,
      }}
    />
  )
}, props => [props.update, props.hidden])

export default withStyles(style)(ActionBreadcrumbs)
