/* global G */
import { useContext, useRef, useState } from 'react'
import { Backdrop, Fade, CircularProgress } from '@mui/material'
import { useStyles, useEventCallback, useEventHandler, useMemoRef } from '@platform/react/hook'
import ApplicationContext from '@platform/react/context/application'
import { flushSync } from 'react-dom'

const raf = window?.requestAnimationFrame || setImmediate || (c => setTimeout(c, 0))

/**
 * Loader component's theme styles.
 *
 * @param {object} theme  the application's current theme
 * @returns {object}      the Loader component's styles
 */
const styles = theme => ({
  root: {
    color: '#FFF',
    // TODO: All modals should be displayed below the loader except for the request timeout one
    zIndex: theme.zIndex.modal + 1,
    // zIndex: theme.zIndex.drawer + 1,
  },
  ...theme.custom.loader,
})

/**
 * Loader Layout component.
 *
 * Displays a semi-transparent layer over all content. To be used as feedback and to prevent
 * user interaction while the application communicates with the server.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Loader = useMemoRef(() => {
  const classes = useStyles(styles)()
  const ref = useRef(null)
  const { eventBus } = useContext(ApplicationContext)
  const [open, setOpen] = useState(false)
  // initialization events handler
  const initEventName = useRef(eventBus.type(G.LOAD, G.INIT))
  const initEventHandler = useEventCallback(() => raf(() => flushSync(() => setOpen(true))))
  useEventHandler(eventBus, initEventName.current, initEventHandler)
  // termination events handler
  const doneEventName = useRef(eventBus.type(G.LOAD, G.DONE))
  const doneEventHandler = useEventCallback(() => raf(() => flushSync(() => setOpen(false))))
  useEventHandler(eventBus, doneEventName.current, doneEventHandler)

  return (
    <Backdrop
      open={open}
      className={classes.root}
      mountOnEnter={true}
      unmountOnExit={true}
      transitionDuration={{
        enter: 1500,
        exit: 500,
      }}
      easing={{
        enter: 'cubic-bezier(1, 0, 1, 0)',
        exit: 'linear',
      }}
      style={{
        // backgroundColor: 'rgba(200, 200, 200, 0.4)',
      }}
    >
      <Fade
        nodeRef={ref}
        in={open}
        timeout={{
          enter: 1500,
          exit: 500,
        }}
        easing={{
          enter: 'cubic-bezier(1, 0, 1, 0)',
          exit: 'linear',
        }}
      >
        <CircularProgress
          ref={ref}
          color="inherit"
        />
      </Fade>
    </Backdrop>
  )
}, [])

export default Loader
