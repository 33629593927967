/* global React, G */
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Document, pdfjs } from 'react-pdf'
import { Box, Grid, Skeleton, Typography, useTheme } from '@mui/material'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { useAttachmentAPI, useStyles } from 'platform/react/hook'
import SvgIcon from 'ui/Element/Icon/Svg'
import Toolbar from 'ui/Element/Attachment/Item/Pdf/Simple/Toolbar'
import Thumbnails from 'ui/Element/Attachment/Item/Pdf/Simple/Thumbnails'
import Pages from 'ui/Element/Attachment/Item/Pdf/Simple/Pages'
import { isObj, isStr } from 'lib/util'
import ApplicationContext from 'platform/react/context/application'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'dist/pdf.worker.js',
  window.location.origin,
).toString()

/**
 * Styles
 *
 * @param {Object} theme    the theme
 * @param {number} spacing  spacing to apply to the elements
 * @returns {Object}
 */
const styles = (theme, { spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: theme.spacing(spacing),
    backgroundColor: theme.palette.background.content,
    height: '100%',
  },
  document: {
    flex: 1,
    overflow: 'hidden',
    gap: '1rem',
    '& .react-pdf__message': {
      width: '100%',
    },
  },
  pageContainer: {
    height: '100%',
  },
  noData: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.black,
  },
})

/**
 * Helper function to show a no data screen in case we couldn't load the PDF.
 *
 * @param {Object} noDataProps            props
 * @param {Object} noDataProps.icon       the icon to show
 * @param {Object} noDataProps.labels     labels to display
 * @param {Object} noDataProps.classes    styles
 * @returns {JSX.Element}
 * @private
 */
const _noData = ({ icon, labels, classes }) => {
  const theme = useTheme()
  return (
    <Box className={classes.root}>
      <SvgIcon
        width={'5rem'}
        height={'5rem'}
        icon={icon.name}
        color={theme.palette.common.black}
        variant={icon.variant}
      />
      <Typography variant={'16/bold'}>{labels.title}</Typography>
      <Typography variant={'14/medium'}>{labels.text}</Typography>
    </Box>
  )
}
/**
 * Helper function to show {@link Skeleton}s when loading the PDF.
 *
 * @param {Object} loadingProps         props
 * @param {string} loadingProps.width   the width of the thumbnail container
 * @param {Object} loadingProps.classes styles
 * @returns {JSX.Element}
 * @private
 */
const _loading = ({ width, classes }) => (
  <Box className={classes.root}>
    <Skeleton
      width={'100%'}
      height={'3rem'}
      variant={'rounded'}
    />
    <Box
      sx={{ display: 'flex' }}
      className={classes.document}
    >
      <Skeleton
        width={width}
        height={'100%'}
        variant={'rounded'}
      />
      <Skeleton
        width={'100%'}
        height={'100%'}
        variant={'rounded'}
      />
    </Box>
  </Box>
)

/**
 * Mapping between breakpoint keywords (like {@code xs}) and actual dimensions
 * in {@code rem}s.
 *
 * @type {{xl: string, md: string, sm: string, xs: string, lg: string}}
 * @private
 */
const _dimensions = {
  xs: '9.5',
  sm: '11',
  md: '13',
  lg: '15',
  xl: '20',
}

/**
 * Simple Pdf Component
 *
 * @param {Object} pdfProps                         props
 * @param {number|string} pdfProps.width            width of the thumbnail bar
 * @param {number} pdfProps.page                    page of PDF to be loaded
 * @param {string} pdfProps.term                    search term to be highlighted
 * @param {...Object} pdfProps.props                additional props
 * @param {string} pdfProps.props.ofLabel           translations of 'of'
 * @param {string} pdfProps.props.currentlyLabel    translations of 'currently'
 * @param {string} pdfProps.props.originalSizeLabel translations of 'Original size'
 * @param {string} pdfProps.props.errorLabel        label when the pdf could not be loaded
 * @param {string} pdfProps.props.errorDescription  description when the pdf could not be loaded
 * @param {string} pdfProps.props.errorIcon         icon when the pdf could not be loaded
 * @param {string} pdfProps.props.events            events passed into this component
 * @param {Function} pdfProps.props.events.onOpen   handler for getting the pdf url
 * @param {Object} ref                              forwarded ref
 * @returns {JSX.Element}
 * @constructor
 */
const SimplePdf = ({ attachment, thumbnailWidth = 'xs', page: initialPage, term: initialSearchTerm, ...props }, ref) => {
  const {
    ofLabel,
    currentlyLabel,
    originalSizeLabel,
    errorLabel,
    closeLabel,
    errorDescription,
    searchLabel,
    errorIcon,
    events,
    spacing = 0,
    showDownloadButton = true,
    searchBannerOnTop = true,
    showThumbnails: showInitialThumbnails = true,
    showFeedback = false,
    showFavorite = false,
  } = props

  const icons = {
    thumbnailIcon: props.thumbnailIcon,
    searchIcon: props.searchIcon,
    minusIcon: props.minusIcon,
    plusIcon: props.plusIcon,
    printIcon: props.printIcon,
    downloadIcon: props.downloadIcon,
    upIcon: props.upIcon,
    downIcon: props.downIcon,
    feedbackIcon: props.feedbackIcon,
    favoriteIcon: props.favoriteIcon,
    noFavoriteIcon: props.noFavoriteIcon,
  }

  const {
    onOpen = null,
    onState = null,
    onAttachment = null,
    onFeedbackOpen = null,
    onFavoriteOpen = null,
    onFavoriteClick = null,
  } = events || {}

  const [item, setItem] = useState(null)
  const [file, setFile] = useState(null)
  const [filename, setFilename] = useState(null)
  const [url, setUrl] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const [numPages, setNumPages] = useState()
  const [pageNumber, setPageNumber] = useState(initialPage || 1)
  const [pageScale, setPageScale] = useState(0.75)
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm || '')
  const [searchResults, setSearchResults] = useState([])
  const [highlightedResult, setHighlightedResult] = useState(-1)
  const [error, setError] = useState(false)

  const [loadedPages, setLoadedPages] = useState([])
  const [showThumbnails, setShowThumbnails] = useState(showInitialThumbnails)

  const classes = useStyles(styles, { spacing })()

  const frameRef = useRef(null)
  const wrapperRef = useRef(null)

  const [documentWidth, setDocumentWidth] = useState(null)

  const onResize = useCallback(() => {
    if (wrapperRef?.current) {
      const documentRect = wrapperRef.current.getBoundingClientRect()
      setDocumentWidth(documentRect.width)
    }
  }, [])

  // Recalculating height if we resize the window
  useEffect(() => {
    window.addEventListener('resize', onResize)

    return () => { window.removeEventListener('resize', onResize) }
  }, [])

  useEffect(() => { onResize() }, [wrapperRef?.current])

  const errorComponent = _noData({
    icon: errorIcon,
    labels: {
      title: errorLabel,
      text: errorDescription,
    },
    classes: { root: classes.noData },
  })

  const { eventBus } = useContext(ApplicationContext)
  const { state } = useAttachmentAPI({ attachment: item || { value: { name: '' } } })

  // In case we get the attachment info through the attachment prop, we need to
  // set item to it.
  useEffect(() => {
    attachment && isObj(attachment) && setItem(attachment)
  }, [attachment])

  useEffect(() => {
    error && eventBus.dispatch(eventBus.type(G.LOAD, G.DONE))
  }, [error])

  useEffect(() => {
    eventBus.dispatch(eventBus.type(G.LOAD, G.INIT));

    // Executing onOpen handler
    (async () => {
      const pdfDoc = attachment ? null : await onOpen?.(null)

      if (pdfDoc) {
        // The handler may return a string, in which case we set the url to it
        // if it returns an object, we assume it's the attachment info, and we
        // set item to it.
        isStr(pdfDoc) ? setUrl(pdfDoc) : setItem(pdfDoc)
      }
    })()
  }, [])

  // Once we fetched the attachment, we set the url to its blob url, also we
  // execute onState handler (if available) so that any parent component that
  // needs information about the attachment state gets it.
  useEffect(() => {
    if (state?.src) {
      onState?.(state)
      setUrl(state.src)
    }
  }, [state?.src])

  // Handler for when the pdf has successfully loaded
  const onDocumentLoadSuccess = async (pdf) => {
    const data = await pdf.getData()

    const newFilename = item
      ? item.value.name
      : pdf._transport._fullReader._filename
    const newFile = new File([data], newFilename, { type: 'application/pdf' })

    setFilename(newFilename)
    setFile(URL.createObjectURL(newFile))
    setIsLoading(false)
    setNumPages(pdf.numPages)
    setPageNumber(initialPage || 1)
  }

  // Handler for then we click on a thumbnail
  const onItemClick = useCallback(({ pageNumber: newPage }) => {
    setPageNumber(newPage)
  }, [])

  // handler for changing a page
  const changePage = (offset) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  // Handle clicking on download button
  const handleDownload = useCallback(() => {
    const link = document.createElement('a')

    const newFilename = !onAttachment ? null : onAttachment?.(
      { value: { value: { name: filename } } },
    )
    const downloadName = !newFilename ? filename : newFilename?.value?.name || filename

    link.setAttribute('download', downloadName)
    link.setAttribute('href', file)
    link.click()
  }, [file, filename])

  // Jump to the first result as soon as we typed in a search term
  // Without this, we need to press the down button once to get to
  // the first result, which isn't good if the result is already on
  // the page we are currently viewing
  useEffect(() => {
    if (searchResults.length && highlightedResult === null) {
      setHighlightedResult(searchResults[0])
    }
  }, [searchResults])

  // Jump to the page with the current search result
  useEffect(() => {
    highlightedResult?.pageNumber && setPageNumber(highlightedResult?.pageNumber)
  }, [highlightedResult])

  // If we load this component with {@code initialSearchTerm} and {@code initialPage},
  // we need to directly jump to the first result on {@initialPage}
  useEffect(() => {
    const initialSearchResultPage = searchResults.find(searchResult => searchResult?.pageNumber === initialPage)
    // Only do this, if we have not already highlighted a specific search
    // result (highlightedResult !== -1). Meaning, only on initial render.
    if (highlightedResult === -1 && initialSearchResultPage) {
      initialSearchTerm === searchTerm
        && initialPage === pageNumber
        && setHighlightedResult(initialSearchResultPage)
    }
  }, [searchResults, highlightedResult])

  useEffect(() => {
    if (!searchTerm || searchTerm === '') {
      setHighlightedResult(-1)
      setSearchResults([])
    }
  }, [searchTerm])

  return (
    <>
      {file && (
        <iframe ref={frameRef} src={file} style={{ display: 'none' }}></iframe>
      )}
      <Grid
        container
        ref={wrapperRef}
        className={classes.root}
      >
        {error || isLoading || !filename ? null : (
          <Toolbar
            icons={icons}
            document={item || { value: { name: filename } }}
            pages={numPages}
            filename={filename}
            frameRef={frameRef}
            pageScale={pageScale}
            width={documentWidth}
            searchTerm={searchTerm}
            currentPage={pageNumber}
            showFeedback={showFeedback}
            showFavorite={showFavorite}
            searchResults={searchResults}
            showThumbnails={showThumbnails}
            searchBannerOnTop={searchBannerOnTop}
            showDownloadButton={showDownloadButton}
            highlightedSearchResult={highlightedResult}
            labels={{
              ofLabel,
              originalSizeLabel,
              currentlyLabel,
              searchLabel,
              closeLabel,
            }}
            children={props.children}
            events={{
              onFeedbackOpen,
              onFavoriteOpen,
              onFavoriteClick,
              onPage: changePage,
              onSearch: setSearchTerm,
              onPageScale: setPageScale,
              onDownload: handleDownload,
              onPageNumber: setPageNumber,
              onThumbnail: setShowThumbnails,
              onSearchResults: setSearchResults,
              onHighlightedSearchResult: setHighlightedResult,
            }}
          />
        )}
        <Grid
          container
          ref={ref}
          file={url}
          noData={null}
          component={Document}
          error={errorComponent}
          onItemClick={onItemClick}
          className={classes.document}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={() => { setError(true) }}
          onSourceError={() => { setError(true) }}
          loading={_loading({ width: `${_dimensions[thumbnailWidth]}rem`, classes })}
        >
          {showThumbnails && (
            <Thumbnails
              width={`${_dimensions[thumbnailWidth]}rem`}
              pages={numPages}
              currentPage={pageNumber}
              loadedPages={loadedPages}
            />
          )}
          <Grid
            item
            xs={true}
            className={classes.pageContainer}
          >
            <Pages
              pages={numPages}
              pageScale={pageScale}
              searchTerm={searchTerm}
              loadedPages={loadedPages}
              width={documentWidth}
              currentPage={pageNumber || initialPage}
              showThumbnails={showThumbnails}
              events={{
                onSearch: setSearchResults,
                onPageScale: setPageScale,
                onPageLoad: setLoadedPages,
              }}
              highlightedSearchResult={highlightedResult}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default SimplePdf
