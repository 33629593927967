/* eslint-disable no-nested-ternary */
import { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { useAttachmentAPI, useMemoRef, useStyles } from '@platform/react/hook'
import ErrorBoundary from 'ui/Error'
import PlaceholderSimple from 'ui/Element/Placeholder/Simple'
import { isObj } from 'lib/util'
import SvgIcon from 'ui/Element/Icon/Svg'
import { empty } from 'lib/util/object'

/**
 * Returns the avatar element's current theme styles.
 *
 * @param {object} theme      the application's current theme
 * @param {boolean} stretch   whether the parent container should get full height
 * @param {string} ratio      ratio to use for the image
 * @param {number} imageRatio natural ratio of the image
 * @returns {object}      the avatar's styles
 */
const styles = (theme, { stretch, ratio, imageRatio }) => ({
  root: {
    // [theme.breakpoints.up('md')]: {
    flex: 1,
    // Makes images parent container take full height, only use this if ratio === null and the
    // image doesn't show because it has 0 height
    ...stretch && {
      height: '100%',
    },
    width: '100%',
    position: 'relative',
    // display: 'inline-block',
    // },
    display: 'flex',
    ...ratio === 'circular' && {
      borderRadius: '50%',
    },
    ...(ratio === 'circular' || ratio === 'square') && {
      aspectRatio: '1 / 1',
    },
    ...ratio === 'native' && {
      aspectRatio: !Number.isNaN(imageRatio) ? imageRatio : 1,
    },
  },
  imgContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  image: {
    // [theme.breakpoints.up('md')]: {
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    minHeight: '100%',
    minWidth: '100%',
    transform: 'translate(-50%, -50%)',
    // },
    maxWidth: '100%',
    width: 'auto',
    height: '100%',
    ...!(ratio === 'circular')
      ? { objectFit: 'contain' }
      : { borderRadius: '50%' },
    aspectRatio: '1 / 1',
  },
  ...theme.custom.image,
})

export const createItem = (ref, name, api = {}) => ({
  key: ref,
  name,
  api: {
    ...api,
    ref,
  },
  value: {
    name,
    type: 'image/jpeg',
  },
})

/**
 * Image Presenter
 *
 * @param {Object} forwardedRef           a reference to the avatar element
 * @param {Object} classes                an object containing the element classes
 * @param {Object} props                  additional props
 * @param {Object} [props.attachment]     attachment document for remote fetching
 * @param {string} [props.value]          key for the ressource to get
 * @param {Object} [props.api]            api information for the ressource to get
 * @param {string} [props.ratio='native'] ratio for the image. Can be 'native', 'square', 'circular'
 * @param {boolean} [props.stretch=false] whether the parent container should get full height
 * @param {String} [filename]             image filename for local images
 * @param {String|Object} fallback        image to show as a fallback
 * @returns {JSX.Element}                 the new avatar element
 * @constructor
 */
const Image = ({ forwardedRef, filename, fallback, ...props }) => {
  const {
    attachment,
    value,
    api,
    ratio = 'native',
    stretch = false,
    skipSkeleton = false,
    events = {},
  } = props

  const valueIsObject = value ? isObj(value) : false
  const ref = valueIsObject ? value.key : value ?? attachment?.key
  const name = valueIsObject ? undefined : 'avatar'

  const imageRef = useRef()
  const [imageRatio, setImageRatio] = useState(null)

  useEffect(() => {
    imageRef?.current
    && setImageRatio(imageRef.current.naturalWidth / imageRef.current.naturalHeight)
  }, [imageRef?.current])

  const classes = useStyles(styles, { stretch, ratio, imageRatio })()

  const item = !empty(attachment)
    ? { api, ...attachment } // if attachment has an api property, it prevails
    : createItem(ref, name, api)

  const fallBackIsIcon = fallback && isObj(fallback)
  const localPath = 'assets/img'

  // IMPORTANT: filename takes precedence over attachment. This means if we have a filename prop,
  // we'll always display this instead of the attachment. However, if we have an attachment prop
  // in addition to this, we also pass it to useAttachmentAPI, but not fetch it yet. The parent
  // component should decide when to fetch it!
  const { state, onLoad, fetch } = useAttachmentAPI({
    attachment: item,
    initial: filename === undefined && !!item.key,
  })

  const { src = null, loading } = state || {}

  // stateSetter is a setState function passed by a parent component that needs information about
  // the attachment. Let's execute it with the current state whenever it changes. But let's only
  // do this if we are working with an actual attachment and not a plain filename to avoid un
  // necessary re-renders.
  useEffect(() => { events?.onState?.({ state, fetch }) }, [state])
  useEffect(() => { events?.onState?.({ state: null, fetch }) }, [attachment])

  const image = filename
    ? filename.includes('http') ? filename : `${localPath}/${filename}`
    : src

  const imageElement = (imageSrc, alt) => imageSrc && (
    <img
      alt={alt}
      ref={imageRef}
      src={imageSrc}
      onLoad={onLoad}
      className={classes.image}
    />
  )

  const iconElement = fallbackSrc => (
    <Box className={classes.imgContainer}>
      <SvgIcon {...fallbackSrc} />
    </Box>
  )

  const { background, ...fallbackProps } = fallBackIsIcon ? fallback : {}

  const img = image
    ? imageElement(image, filename)
    : fallBackIsIcon
      ? iconElement(fallbackProps)
      : fallback ? imageElement(`${localPath}/${fallback}`, fallback) : null

  return (
    <ErrorBoundary>
      <Box
        ref={forwardedRef}
        className={classes.root}
        sx={{
          backgroundColor: (!loading || !item.key) && fallBackIsIcon ? background : null,
        }}
      >
        {!filename && !!item.key && loading
          ? !skipSkeleton && (
            <PlaceholderSimple
              isHidden={false}
              placeholderProps={{
                height: '100%',
                ...ratio === 'circular' && { variant: 'circular' },
              }}
            />
          )
          : img}
      </Box>
    </ErrorBoundary>
  )
}

export default useMemoRef(Image, props => [props.filename, props.attachment, props.value])
