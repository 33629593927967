/* global G */
import { Box, lighten } from '@mui/material'
import { Page } from 'react-pdf'
import { useMemoRef, useStyles } from 'platform/react/hook'
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { isObj } from 'lib/util'
import ApplicationContext from 'platform/react/context/application'

const styles = theme => ({
  pageScrollContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    borderRadius: '0.5rem',
  },
  pageContent: {
    position: 'absolute',
    margin: '0.5rem',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    overflow: 'auto',
    scrollbarWidth: 'auto!important',
    '&::-webkit-scrollbar': {
      display: 'block',
      overflow: 'auto',
      width: '0.5rem',
      height: '0.5rem',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '0.5rem',
      backgroundColor: lighten(theme.palette.common.black, 0.7),
    },
  },
  loadedPage: {
    '& .react-pdf__Page__canvas': {
      border: `1px solid ${theme.palette.common.black}`,
    },
  },
  page: {
    display: 'flex',
    justifyContent: 'center',
    '& .react-pdf__Page__textContent': {
      left: 'auto!important',
      right: 'auto!important',
      '& > span > mark': {
        borderRadius: '0.5rem',
        backgroundColor: theme.palette.text.light,
        boxShadow: `0px 0px 10px 0px ${theme.palette.text.regular}`,
        '&.highlight': {
          backgroundColor: theme.palette.warning.main,
        },
      },
    },
    '& .react-pdf__Page__annotations': {
      left: 'auto!important',
      right: 'auto!important',
    },
    '&:not(:last-child)': {
      paddingBottom: '0.5rem',
    },
  },
})

/**
 * Checks whether {@param el1} is in the visible area of {@param el2}
 * @param el1
 * @param el2
 * @returns {boolean}
 */
const isVisible = (el1, el2) => {
  const rect1 = el1.getBoundingClientRect()
  const rect2 = el2.getBoundingClientRect()

  return (
    ((rect2.top <= rect1.top) && (rect1.top <= rect2.bottom))
      && ((rect2.top <= rect1.bottom) && (rect1.bottom <= rect2.bottom))
      && ((rect2.left <= rect1.left) && (rect1.left <= rect2.right))
      && ((rect2.left <= rect1.right) && (rect1.right <= rect2.right))
  )
}

/**
 * Simple Pdf Pages Component
 *
 * Shows a container with all pages of the current pdf.
 *
 * @param {Object} pagesProps                               props
 * @param {React.Ref} pagesProps.forwardedRef               ref to the dom node
 * @param {Object} pagesProps.classes                       styles from parent component
 * @param {number} pagesProps.currentPage                   the current page
 * @param {number} pagesProps.pages                         number of pages
 * @param {number} pagesProps.pageScale                     the current page scale
 * @param {Object} pagesProps.events                        events for the component
 * @param {Function} pagesProps.events.onSearch             handler when the search term changes
 * @param {Object} pagesProps.props                         additional props
 * @param {Object} pagesProps.props.searchTerm              the current search term
 * @param {Object} pagesProps.props.highlightedSearchResult the currently highlighted search term
 * @returns {JSX.Element}
 * @constructor
 */
const Pages = ({
  forwardedRef,
  classes: parentClasses,
  currentPage,
  pages,
  pageScale,
  events,
  ...props
}) => {
  const classes = { ...parentClasses, ...useStyles(styles)() }
  const { eventBus } = useContext(ApplicationContext)

  const { onSearch = null, onPageScale = null, onPageLoad = null } = events || {}
  const { searchTerm, highlightedSearchResult, loadedPages } = props

  const pageRef = useRef(null)

  useEffect(() => {
    // If we have request a initial page, and it's not the first one
    // disable loader after all pages have been loaded
    if (Number.isInteger(currentPage)
        && currentPage !== 1
        && isObj(loadedPages)
        && Object.keys(loadedPages).length === pages) {
      eventBus.dispatch(eventBus.type(G.LOAD, G.DONE))
    }

    // If we have not requested an initial page, or it's the first one
    // disable loader after first page has been loaded
    if ((currentPage === undefined || currentPage === 1) && Object.keys(loadedPages).includes('0')) {
      eventBus.dispatch(eventBus.type(G.LOAD, G.DONE))
    }
  }, [loadedPages])

  // Handler for ctrl + mwheel
  const zoomHandler = (e) => {
    if (e.ctrlKey) {
      e.preventDefault()
      e.stopPropagation()
      Math.sign(e.deltaY) === 1 && onPageScale?.(prevState => prevState + 0.125)
      Math.sign(e.deltaY) === -1 && onPageScale?.(prevState => (prevState > 0.25 ? prevState - 0.125 : 0.25))
    }
  }

  // Registering zoom event handler, no need for cleanup here
  useEffect(() => {
    if (pageRef?.current) {
      pageRef.current.addEventListener?.('wheel', zoomHandler)
    }
  }, [pageRef?.current])

  // Return the highlighted pattern with a mark, the current on in a different color
  // Look at {@link styles} to see how we style the marks
  const highlightPattern = (text, pattern, highlight) => text?.str.replace(new RegExp(pattern, 'i'), value => (
    text.pageIndex === highlight?.pageIndex && text.itemIndex === highlight?.itemIndex
      ? `<mark id="${text.pageIndex}" class="highlight">${value}</mark>`
      : `<mark>${value}</mark>`))

  /**
   * Comparison function that sorts items after {code itemIndex}
   * @param {Object} a   the first item
   * @param {Object} b  the second item
   * @returns {number}
   */
  const comparator = (a, b) => a.pageIndex - b.pageIndex || a.itemIndex - b.itemIndex

  // Handler for rendering text above the pdf, this is needed when searching for a term
  const textRenderer = useCallback(
    (textItem) => {
      if (textItem?.str?.toLowerCase().includes(searchTerm?.toLowerCase()) && searchTerm?.length) {
        onSearch?.((prevResults) => {
          const alreadyFound = prevResults.some(
            result => result.pageIndex === textItem.pageIndex
                && result.itemIndex === textItem.itemIndex,
          )
          const newResults = !alreadyFound ? [...prevResults, textItem] : prevResults
          // We need to sort the search results by itemIndex, because the initial page we rendered
          // this pdf with might not have been page one, in that case the search result found on
          // that page will be the first in the list, but it's not the first in the pdf
          return newResults.sort(comparator)
        })
      }
      return highlightPattern(textItem, searchTerm, highlightedSearchResult)
    },
    [searchTerm, highlightedSearchResult],
  )

  // Only render text layer after all pages have been loaded
  const shouldRenderTextLayer = useMemo(
    () => Object.keys(loadedPages).length === pages,
    [pages, loadedPages],
  )

  return (
    <Box
      ref={forwardedRef}
      className={classes.pageScrollContainer}
    >
      <Box
        ref={pageRef}
        className={classes.pageContent}
      >
        <>
          {Array.from(new Array(pages), (el, i) => (
            i + 1 !== currentPage && !loadedPages[currentPage - 1]
              ? null
              : <Page
                loading={null}
                key={`page-${i}`}
                width={props.width}
                pageNumber={i + 1}
                scale={pageScale || 1}
                renderTextLayer={shouldRenderTextLayer}
                className={`${classes.page} ${loadedPages[i] ? classes.loadedPage : ''}`}
                onRenderSuccess={() => {
                  onPageLoad?.(prevState => ({ ...prevState, [i]: true }))
                }}
                onRenderTextLayerSuccess={() => {
                  if (highlightedSearchResult?.itemIndex) {
                    const highlightedElement = document.getElementsByClassName('highlight')[0]
                    const pageIndex = highlightedElement?.id || null

                    if (pageIndex === i.toString()) {
                      !isVisible(highlightedElement, pageRef?.current)
                        && highlightedElement.scrollIntoView({ inline: 'nearest', block: 'nearest' })
                    }
                  }
                }}
                customTextRenderer={textRenderer}
                inputRef={
                  (currentPage === i + 1) || (currentPage === '' && i === 0)
                    ? (currentPageRef) => {
                      currentPageRef && currentPageRef.scrollIntoView()
                    }
                    : null
                }
              />
          ))}
        </>
      </Box>
    </Box>
  )
}

export default useMemoRef(Pages, props => [
  props.classes,
  props.pages,
  props.currentPage,
  props.pageScale,
  props.loadedPages,
  props.showThumbnails,
  props.searchTerm,
  props.width,
  props.highlightedSearchResult,
])
