/* global React */
import { useEffect, useState } from 'react'
import { Box, lighten, useTheme } from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'
import { getThemeColor } from 'lib/util'

// eslint-disable-next-line no-unused-vars
const styles = (theme, { background, color, padding }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: (background && getThemeColor(theme, background)) || (color
      ? lighten(getThemeColor(theme, color), 0.9)
      : theme.palette.background.selected),
    borderRadius: '50%',
    padding: padding || theme.spacing(1.5),
  },
  icon: {
    maskSize: 'contain',
    maskPosition: 'center',
    maskRepeat: 'no-repeat',
  },
  ...theme.custom.svgIcon,
})

const roundedStyles = (theme, { size, background, color }) => ({
  root: {
    height: size,
    width: size,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: background || (color
      ? lighten(color, 0.9)
      : theme.palette.background.selected),
    borderRadius: '50%',
  },
})

/**
 * Use external SVG images.
 * https://caniuse.com/?search=mask
 *
 * @type {React.FunctionComponent}
 *
 * @param {Object} iconProps                                props for the component
 * @param {object} iconProps.forwardedRef                   a reference to the root element.
 * @param {String} iconProps.icon                           name of the icon to use
 *                                                          (e.g. {@code device})
 * @param {String|null} iconProps.color                     color of the icon
 *                                                          (e.g. {@code primary.main})
 * @param {('filled'|'outlined'|null)} iconProps.variant    variant of the icon
 * @param {Number|String} [iconProps.size=24]               height and width of the icon
 * @param {Number|String} [iconProps.width=iconProps.size]  width of the icon
 * @param {Number|String} [iconProps.height=iconProps.size] height of the icon
 * @param {boolean} [iconProps.rounded]                     whether icon has a rounded background
 * @param {String} [iconProps.background]                   color if {@param rounded} is true
 * @param {Number|String} [className]                       class name for styling
 * @returns {JSX.Element}
 * @constructor
 */
const SvgIcon = ({
  forwardedRef,
  icon = 'generic',
  color = null,
  variant = 'outlined',
  size = 24,
  width = size,
  height = size,
  rounded,
  background,
  padding = null,
  className,
}) => {
  const [src, setSrc] = useState(null)
  const classes = useStyles(styles, { background, color, padding })()
  const theme = useTheme()

  const iconClasses = [
    classes.icon,
    ...className ? [className] : [],
  ].join(' ')

  useEffect(() => {
    let active = true

    if (icon) {
      const url = `assets/icon/${variant ? `${variant}/` : ''}`

      active && setSrc(`${url}${icon || 'generic'}.svg`)
    }

    return () => { active = false }
  }, [icon, variant])

  return !src ? null : (
    <>
      {rounded ? (
        <Box className={classes.root}>
          <Box
            ref={forwardedRef}
            className={iconClasses}
            sx={{
              width,
              height,
              maskImage: `url(${src})`,
              backgroundColor: (color && getThemeColor(theme, color)) || theme.palette.common.black,
            }}
          />
        </Box>
      ) : (
        <Box
          ref={forwardedRef}
          className={iconClasses}
          sx={{
            width,
            height,
            maskImage: `url(${src})`,
            backgroundColor: color || theme.palette.common.black,
          }}
        />
      )}
    </>
  )
}

/**
 * SVG Rounded Icon
 *
 * Wraps {@link SvgIcon} in a circular background. Similiar to a FAB icon.
 *
 * @type {React.FunctionComponent}
 *
 * @param {Object} iconProps
 * @param {number} [iconProps.size=40]    size of the circular background
 * @param {string} [iconProps.background] optional background color for the rounded icon.
 *                                        If missing, it will use a lightened version of
 *                                        {@param props.color}
 * @param {Object} iconProps.props        props for the icon
 * @returns {JSX.Element}
 * @constructor
 */
export const SvgRoundedIcon = ({ size = 40, background, ...props }) => {
  const classes = useStyles(roundedStyles, {
    size,
    background,
    color: props.color,
  })()

  return (
    <Box className={classes.root}>
      <SvgIcon
        {...props}
        rounded={true}
      />
    </Box>
  )
}

export const SvgFileIcon = ({ ...props }) => SvgIcon({ ...props, variant: 'file' })

export default useMemoRef(SvgIcon, props => [props.icon, props.color, props.variant])
