/* eslint-disable no-nested-ternary */
import { useMemoRef, useStyles } from '@platform/react/hook'
import { Card, CardContent, CardHeader, Grid, Link, Typography, useTheme } from '@mui/material'
import SvgIcon from 'ui/Element/Icon/Svg'
import { getThemeColor } from 'lib/util'
import { useEffect, useState } from 'react'
import ErrorBoundary from 'ui/Error'
import PlaceholderSimple from 'ui/Element/Placeholder/Simple'
import Chip from 'ui/Element/Text/Chip'

const styles = (theme, { icon, background, bold, backgroundImage }) => ({
  root: {
    width: '100%',
    border: 'none',
    padding: theme.spacing(1.5),
    cursor: 'pointer',
    position: 'relative',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    ...!icon && {
      height: '10rem',
    },
    '&:active': {
      opacity: 0.4,
    },
    ...background && { backgroundColor: getThemeColor(theme, background) },
    ...backgroundImage && {
      '&:before': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        // TODO: maybe it would be actually better to use an img element in order to pin the image
        background: `${theme.palette.gray[960]} url(/assets/${backgroundImage}) 130% -3.5rem / contain no-repeat`,
        filter: 'grayscale(100%)',
        content: '""',
        zIndex: 0,
        opacity: 0.4,
      },
    },
  },
  header: {
    padding: 0,
    marginBottom: theme.spacing(2),
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  content: {
    paddingBottom: 0,
    '&:last-child': {
      // padding: 0,
    },
    ...!icon && {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    ...backgroundImage && {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
    },
  },
  label: {
    fontWeight: bold ? 700 : 500,
    // fontSize: bold ? '0.875rem' : '1rem', // 14px / 16px, bold is smaller, but thicker
  },
  subLabel: {
    fontWeight: 400,
  },
  iconContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
  },
  link: {
    textDecoration: 'none',
  },
  ...theme.custom.cardSimple,
})

/**
 * Chip text element used in the card to display a short informative message.
 *
 * @param {string} text   the element's content
 * @returns {JSX.Element}
 * @constructor
 */
const TagLabel = ({ text }) => (
  <Grid
    item
    container
    sx={{ mb: 1 }}
    xs={'auto'}
  >
    <Grid
      item
      component={Chip}
      text={text}
      xs={'auto'}
    />
  </Grid>
)

/**
 * Displays a simple card
 *
 * Can also fetch data using the {@param events.onOpen} handler. There are 3 possible scenarios:
 *
 * - 1: We don't have an onOpen handler. In this case we simply show the card without any delay.
 * - 2: We have an onOpen handler, but it doesn't return data. In this case we wait for the handler
 *   to finish, but don't do anything with its (non-existent) result and show the card.
 * - 3: We have an onOpen handler, and it returns useful data: In this case we set {@code data} to
 *   the incoming data, then show the card. We also pass the data to the onClick handler. It will
 *   pass it to the new action and let it do its thing.
 *
 * In any case, if there is an onOpen handler, we will display a skeleton until it returns.
 *
 * @param {React.Ref} forwardedRef  ref to the element
 * @param {Object} events           events for the element
 * @param {Object} props            additional props
 * @returns {JSX.Element}
 * @constructor
 */
const SimpleCard = ({ forwardedRef, events, ...props }) => {
  const theme = useTheme()
  const { xs, sm, md, lg, xl, variant = 'outlined', background: cardBackground, ...options } = props

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(!!events?.onOpen)

  const {
    icon,
    tagLabel,
    label,
    subLabel,
    bold = false,
    backgroundImage,
    link = false,
    target = '_blank',
  } = options
  const { labelVariant, subLabelVariant } = options
  const { onClick = null, onOpen = null } = events || {}

  const classesProps = { icon, background: cardBackground, bold, backgroundImage }
  const classes = useStyles(styles, classesProps)()

  useEffect(() => {
    if (data !== null && loading) {
      setLoading(false)
    }
  }, [data, loading])

  useEffect(() => {
    (async () => {
      const result = await onOpen?.(null)

      result
        ? setData(result)
        : setLoading(false)
    })()
  }, [])

  const {
    variant: iconVariant = 'outlined',
    width = 24,
    height = 24,
    name = 'device',
    color = 'common.black',
    background = 'background.selected',
  } = icon || {}

  const content = (
    <Card
      variant={variant}
      onClick={
        // Passing the data that the onOpen handler fetched to the onClick handler. It may contain
        // stuff we need in the action we load from here. This avoids having to temporarily save
        // the data in the module state
        e => onClick?.(data
          ? { ...e, detail: data }
          : e)
      }
      classes={{ root: classes.root }}
    >
      {icon && (
        <CardHeader
          classes={{
            root: classes.header,
            avatar: classes.avatar,
          }}
          avatar={
            <>
              <SvgIcon
                icon={name}
                rounded={true}
                width={width}
                height={height}
                variant={iconVariant}
                color={getThemeColor(theme, color)}
                background={getThemeColor(theme, background)}
              />
              {tagLabel && <TagLabel text={tagLabel} />}
            </>
          }
        />
      )}
      <CardContent
        classes={{ root: classes.content }}
      >
        <Typography
          classes={{ root: classes.label }}
          color={'textBlack'}
          variant={labelVariant || 'subtitle1'}
        >
          {label}
        </Typography>
        {!icon && tagLabel && <TagLabel text={tagLabel} />}
        <Typography
          classes={{ root: classes.subLabel }}
          color={'textSecondary'}
          variant={subLabelVariant || 'subtitle2'}
        >
          {subLabel}
        </Typography>
      </CardContent>
    </Card>
  )

  return (
    <ErrorBoundary>
      <Grid
        item
        container
        ref={forwardedRef}
        alignItems={'center'}
        justifyContent={'center'}
        {...link && data && {
          className: classes.link,
          component: Link,
          href: data,
          target,
        }}
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
      >
        {loading ? (
          <PlaceholderSimple timeout={500}>
            {content}
          </PlaceholderSimple>
        // In case there is an onOpen handler, but it didn't return any data, don't show
        // the card. If there is no onOpen handler, we show the card in any case.
        ) : onOpen && !data ? null : content}
      </Grid>
    </ErrorBoundary>
  )
}

export default useMemoRef(SimpleCard, [])
