import { Box, ButtonBase, InputAdornment, InputBase, Paper, Slide, Typography } from '@mui/material'
import SvgIcon from 'ui/Element/Icon/Svg'
import { useCallback, useEffect } from 'react'
import { useStyles } from 'platform/react/hook'
import { debounce } from 'lib/util'

const styles = (theme, { searchBannerOnTop }) => ({
  searchBanner: {
    display: 'flex',
    justifyContent: 'center',
    gap: '0.5rem',
    alignItems: 'center',
    backgroundColor: 'white',
    position: 'fixed',
    ...searchBannerOnTop ? {
      top: 0,
      borderBottomWidth: 1,
    } : {
      bottom: 0,
      borderTopWidth: 1,
    },
    left: 0,
    right: 0,
    margin: 0,
    padding: '1rem',
    borderWidth: 0,
    zIndex: 1,
  },
})

/**
 * Helper function to get the index of the currently highlighted search term
 *
 * @param {Object[]} array  list of all search terms
 * @param {Object} target   currently highlighted search term
 * @returns {*}
 * @private
 */
const _getIndexOf = (array, target) => array.findIndex(
  item => item.pageIndex === target?.pageIndex && item.itemIndex === target?.itemIndex,
)

/**
 * Pdf Search Bar
 *
 * Shows a search bar at either the top or bottom of the screen, depending on
 * {@param searchBannerOnTop}.
 *
 * @param {string} inputValue           the value of the input field
 * @param {string} searchTerm           the current search term
 * @param {boolean} showSearch          whether or not to show the search bar
 * @param {boolean} searchBannerOnTop   whether to show the search on top or bottom
 * @param {Object} parentClasses        styling from parent component
 * @param {Object} events               events from parent component
 * @param {Object} props                additional props
 * @returns {JSX.Element}
 * @constructor
 */
const Search = ({
  inputValue,
  searchTerm,
  showSearch,
  searchBannerOnTop,
  classes: parentClasses,
  events,
  ...props
}) => {
  const classes = { ...parentClasses, ...useStyles(styles, { searchBannerOnTop })() }

  const { setInputValue, setShowSearch, onSearch, onHighlightedSearchResult, onSearchResults } = events
  const { searchResults, highlightedSearchResult, icons, labels } = props

  // Whether the search previous (arrow up) icon is disabled
  const isSearchUpDisabled = !searchResults?.length
        || searchTerm === null
        || _getIndexOf(searchResults, highlightedSearchResult) <= 0

  // Whether the search next (arrow down) icon is disabled
  const isSearchDownDisabled = !searchResults?.length
        || searchTerm === null
        || _getIndexOf(searchResults, highlightedSearchResult) === searchResults.length - 1

  // Search previous handler
  const searchUpHandler = () => {
    onHighlightedSearchResult?.((prevState) => {
      if (!prevState) return searchResults[0]
      const currentIndex = _getIndexOf(searchResults, prevState)
      return searchResults[currentIndex - 1]
    })
  }

  // Search next handler
  const searchDownHandler = () => {
    onHighlightedSearchResult?.((prevState) => {
      if (prevState === null) return searchResults[0]
      const currentIndex = _getIndexOf(searchResults, prevState)
      return searchResults[currentIndex + 1] ?? searchResults[0]
    })
  }

  // Setting the global search term and results debounced
  const debouncedChangeHandler = useCallback(debounce((value) => {
    onSearchResults?.([])
    onSearch?.(value)
    onHighlightedSearchResult?.(null)
  }, 300), [])

  // Setting the local input value immediately, but debouncing the global search term
  const handleChange = ({ target: { value: term } }) => {
    setInputValue?.(term)
    debouncedChangeHandler?.(term)
  }

  // Creates the string, like '1/7' for the search input field
  const searchResultsIndex = useCallback(() => {
    const highlightedSearchResultId = searchResults.findIndex(
      item => item.pageIndex === highlightedSearchResult?.pageIndex
            && item.itemIndex === highlightedSearchResult?.itemIndex,
    )

    return highlightedSearchResultId !== -1
      ? `${highlightedSearchResultId + 1}/${searchResults.length}`
      : searchResults.length
  }, [searchResults, highlightedSearchResult])

  // Reset search if we close the input field
  useEffect(() => { !showSearch && onSearch?.(null) }, [showSearch])

  // Removing the local input value if the search is closed
  useEffect(() => { !showSearch && setInputValue('') }, [showSearch])

  return (
    <Slide
      mountOnEnter
      unmountOnExit
      in={showSearch}
      direction={searchBannerOnTop ? 'down' : 'up'}
    >
      <Paper
        square
        tabIndex={-1}
        role={'dialog'}
        variant={'outlined'}
        aria-modal={'false'}
        aria-label={'Search banner'}
        className={classes.searchBanner}
      >
        <InputBase
          placeholder={labels.search || ''}
          autoFocus={true}
          value={inputValue || ''}
          onKeyDownCapture={(e) => {
            e.key === 'Enter' && searchDownHandler?.()
          }}
          onChange={handleChange}
          className={classes.searchInput}
          endAdornment={
            !searchResults?.length ? null : (
              <InputAdornment position={'end'}>
                <Typography
                  variant={'14/medium'}
                  sx={{ marginRight: '0.5rem' }}
                >
                  {searchResultsIndex()}
                </Typography>
                <Box
                  onClick={() => {
                    setInputValue('')
                    onSearch?.(null)
                  }}
                  sx={{ marginRight: '0.5rem', cursor: 'pointer' }}
                >
                  <SvgIcon
                    width={'1rem'}
                    height={'1rem'}
                    icon={'close'}
                    variant={'filled'}
                  />
                </Box>
              </InputAdornment>
            )
          }
        />
        <ButtonBase
          onClick={searchUpHandler}
          disabled={isSearchUpDisabled}
          className={`${classes.button} ${classes.upButton}`}
        >
          {icons.up}
        </ButtonBase>
        <ButtonBase
          onClick={searchDownHandler}
          disabled={isSearchDownDisabled}
          className={`${classes.button} ${classes.downButton}`}
        >
          {icons.down}
        </ButtonBase>
        <ButtonBase
          className={classes.textButton}
          onClick={() => { setShowSearch(prevState => !prevState) }}
        >
          <Typography variant={'14/medium'}>
            {labels.close}
          </Typography>
        </ButtonBase>
      </Paper>
    </Slide>
  )
}

export default Search
