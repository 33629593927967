/* eslint-disable arrow-body-style, object-curly-newline,no-unused-vars */
/* global React */
import { useEffect, useState, useTransition } from 'react'
import { FormControl, FormControlLabel, Checkbox, FormHelperText } from '@mui/material'
import useMemoRef from '@platform/react/hook/useMemoRef'
import { useStyles } from '@platform/react/hook'
import PlatformEvent from 'lib/util/event'

const styles = theme => ({
  link: {
    '& a': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      fontWeight: 'bold',
    },
  },
  label: {
    marginLeft: -6,
    userSelect: 'none',
  },
  helperText: {
    margin: 0,
  },
  ...theme.custom.checkField,
})

/**
 * Checkbox Element
 *
 * providing checked attribute to the element will throw a React warning in regard to
 * switching from uncontrolled to controlled component,
 * so we need to convert checked to defaultChecked
 *
 * controlled component - we need to propagate new (props) checked on each change.
 * uncontrolled component - we set defaults (props) and let component take care of itself,
 * until we force a new set of props.
 *
 * More info: https://fb.me/react-controlled-components
 *
 * @param {object} forwardedRef     ref to the element
 * @param {object} events           checkbox events
 * @param {string} label            label to display
 * @param {string} color            color for the label
 * @param {string} size             size for the checkbox
 * @param {boolean} checked         initial state
 * @param {string} helperText       error text
 * @param {boolean} error           whether to display {@param helperText}
 * @param {object} props            additional props
 * @param {boolean} props.checked   checked state
 * @param {boolean} props.value     value state
 * @return {*}
 * @constructor
 */
const CheckField = ({ forwardedRef, events, label, color, size, helperText, error, ...props }) => {
  const [, startTransition] = useTransition()
  const classes = useStyles(styles)()
  const [checked, setChecked] = useState(props.checked)

  const handleChange = (event) => {
    const { value, checked: isChecked } = event.target
    setChecked(isChecked)
    startTransition(() => events?.onChange(new PlatformEvent(event, { value, checked: isChecked })))
  }

  useEffect(() => {
    setChecked(props.checked)
  }, [props.checked])

  return (
    <FormControl
      error={error}
    >
      <FormControlLabel
        color={color || 'textBlack'}
        className={classes.label}
        control={
          <Checkbox
            value={''}
            {...props}
            ref={forwardedRef}
            color={'secondary'}
            checked={checked}
            onChange={handleChange}
            size={size}
          />
        }
        label={
          <span className={classes.link}>{label}</span>
        }
      />
      {helperText && (
        <FormHelperText
          className={classes.helperText}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default useMemoRef(CheckField, props => [
  props.checked, props.value, props.disabled, props.error, props.helperText,
])
