/* global G */
import { Box, Checkbox, lighten, TableCell, TableRow, useTheme } from '@mui/material'
import {
  ArrowForward as ArrowForwardIcon,
  Description as DescriptionIcon,
  Engineering as EngineeringIcon,
  Assignment as AssigmentIcon,
  Link as LinkIcon,
  ShoppingCart as ShoppingCartIcon,
} from '@mui/icons-material'
import { useMemoRef, useStyles } from '@platform/react/hook'
import SvgIcon from 'ui/Element/Icon/Svg'
import { useContext, useState } from 'react'
import { PlatformEvent } from 'lib/util'
import ApplicationContext from '@platform/react/context/application'

const styles = (theme, { isSelected, hasLeafSiblings }) => ({
  row: {
    '& .MuiTableCell-root': {
      fontSize: '0.75rem', // 12px
      fontWeight: 500,
      height: '2.5rem',
      letterSpacing: 'normal',
      color: theme.palette.common.black,
      backgroundColor: isSelected ? theme.palette.background.light : 'inherit',
      borderRight: `1px solid ${lighten(theme.palette.border.main, 0.5)}`,
      borderBottom: `1px solid ${lighten(theme.palette.border.main, 0.5)}`,
      padding: theme.spacing(0.5, 1.5),
    },
    '& :last-child': {
      borderRight: 'none',
    },
    '& :hover': {
      cursor: 'pointer',
    },
  },
  articleCell: {
    ...hasLeafSiblings && { width: '7rem' },
  },
  buttonCell: {
    width: '2.5rem', // 40px
    lineHeight: 1,
    padding: theme.spacing(1, 1.5),
  },
  amountCell: {
    width: '7.5rem', // 120px
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  cellContent: {
    flex: 1,
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cellIcons: {
    gap: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  name: {
    textDecoration: isSelected ? 'underline' : 'none',
  },
  ...theme.custom.PartsListRow,
})

/**
 * A list item component representing a part of the {@link PartsList}.
 *
 * @param {Object} attachment         the attachment to download
 * @param {Object} node               the current node to render.
 * @param {Object} events             events passed from parent component
 * @param {Object} options            additional props
 * @returns {JSX.Element}
 * @constructor
 */
const Part = ({ node, isSelected, events, multiSelection, labels, ...options }) => {
  const { eventBus } = useContext(ApplicationContext)
  const theme = useTheme()
  const { onClick, onDescription, onAddPart, onDocumentation } = events
  const [isHovered, setIsHovered] = useState(false)

  const hasLeafSiblings = node?.parent?.$children?.some?.(child => child.type !== 'Directory')
      || false
  const classes = useStyles(styles, { isSelected, hasLeafSiblings })()

  const handleYank = async (e) => {
    e.stopPropagation()

    await navigator.clipboard.writeText(node.orderNumber)

    eventBus.dispatch(eventBus.type(G.DATA, G.UPDATE), {
      message: labels.copy,
      severity: 'success',
      variant: 'filled',
      duration: 3000,
      color: 'primary',
      close: false,
    })
  }

  const handleAddPart = (event) => {
    const platformEvent = new PlatformEvent(event, { node })
    onAddPart?.(platformEvent)
  }

  const handleClick = (event, id) => {
    const platformEvent = new PlatformEvent(event, { id })
    onClick?.(platformEvent)
  }

  const orderIcon = options.orderIcon
    ? <SvgIcon
      rounded={true}
      width={'1rem'}
      height={'1rem'}
      padding={'0.25rem'}
      icon={options.orderIcon.name}
      background={theme.palette.common.white}
      color={theme.palette.primary.main}
      variant={options.orderIcon.variant}
    />
    : <ShoppingCartIcon/>

  const copyIcon = options.copyIcon
    ? <SvgIcon
      width={'1rem'}
      height={'1rem'}
      icon={options.copyIcon.name}
      variant={options.copyIcon.variant}
    />
    : <LinkIcon/>

  const forwardIcon = options.forwardIcon
    ? <SvgIcon
      rounded={true}
      width={'1rem'} // 16px
      height={'1rem'}
      padding={'0.25rem'}
      icon={options.forwardIcon.name}
      color={theme.palette.primary.main}
      variant={options.forwardIcon.variant}
    />
    : <ArrowForwardIcon/>

  const descriptionIcon = options.descriptionIcon
    ? <SvgIcon
      rounded={true}
      width={'1.25rem'} // 16px
      height={'1.25rem'}
      padding={'0.375rem'}
      background={'gray.950'}
      icon={options.descriptionIcon.name}
      variant={options.descriptionIcon.variant}
    />
    : <DescriptionIcon />

  const documentationIcon = options.documentationIcon
    ? <SvgIcon
      rounded={true}
      width={'1.25rem'} // 16px
      height={'1.25rem'}
      padding={'0.375rem'}
      background={'gray.950'}
      icon={options.documentationIcon.name}
      variant={options.documentationIcon.variant}
    />
    : <AssigmentIcon />

  const partIcon = options.partIcon
    ? <SvgIcon
      icon={options.partIcon.name}
      variant={options.partIcon.variant}
    />
    : <EngineeringIcon />

  const assemblyIcon = options.assemblyIcon
    ? <SvgIcon
      icon={options.assemblyIcon.name}
      variant={options.assemblyIcon.variant}
    />
    : <EngineeringIcon />

  let siblingNodes = []
  if (node.type === 'File') {
    siblingNodes = node.parent.$children
      .filter(sibling => sibling.pos === node.pos)
      .map(x => x.id)
  }

  return (
    <TableRow
      hover
      id={node.id}
      className={classes.row}
      onMouseEnter={() => { setIsHovered(true) }}
      onMouseLeave={() => { setIsHovered(false) }}
      onClick={event => handleClick(event, siblingNodes.length > 1 ? siblingNodes : node.id)}
    >
      <TableCell sortDirection={'desc'}>
        {node.pos}
      </TableCell>
      <TableCell>
        <Box className={classes.nameCell}>
          <Box sx={{ marginRight: '0.5rem' }}>
            {node.type === 'Directory'
              ? assemblyIcon
              : partIcon}
          </Box>
          <Box className={classes.cellContent}>
            <span className={classes.name}>
              {node.name || '-'}
            </span>
            <Box className={classes.cellIcons}>
              {!node?.documentation?.length ? null : (
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    onDocumentation?.(new PlatformEvent(e, { documentation: node.documentation }))
                  }}
                >
                  {documentationIcon}
                </Box>
              )}
              {!node?.description?.length ? null : (
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    onDescription?.(node)
                  }}
                >
                  {descriptionIcon}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell className={classes.articleCell}>
        <Box className={classes.cellContent}>
          {node.orderNumber || '-'}
          {!multiSelection || node.type === 'Directory' ? null : (
            <Checkbox
              size={'small'}
              sx={{ padding: 0 }}
              onChange={() => events?.onCheck?.(node.orderNumber)}
            />
          )}
        </Box>
      </TableCell>
      <TableCell className={classes.amountCell}>
        <Box className={classes.cellContent}>
          {!isHovered || node.type === 'Directory'
            ? node.amount || '-'
            : <>
              {!node.orderable ? null
                : <Box onClick={handleAddPart}>
                  {orderIcon}
                </Box>
              }
              <Box onClick={handleYank}>
                {copyIcon}
              </Box>
            </>
          }
        </Box>
      </TableCell>
      <TableCell className={classes.buttonCell}>
        {node.type === 'Directory' && (
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={event => handleClick(event, node.id)}
          >
            {forwardIcon}
          </Box>
        )}
      </TableCell>
    </TableRow>
  )
}

export default useMemoRef(Part, props => [props.node, props.isSelected, props.multiSelection])
