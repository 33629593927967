import { useEffect, useRef, useState } from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { useStyles } from '@platform/react/hook'

/**
 * Determining whether {@param e} is overflowing or not.
 *
 * @param {Object} e    ref to the element
 *
 * @return {Boolean}
 * @private
 */
const _isElementOverflowing = e => e.offsetWidth < e.scrollWidth || e.offsetHeight < e.scrollHeight

/**
 * OverflowTooltip's styles.
 *
 * @param theme
 * @return {*&{root: {overflow: string}, label: {overflow: string, whiteSpace: string, textOverflow: string}}}
 */
const styles = theme => ({
  root: { overflow: 'hidden' },
  label: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  ...theme.custom.overflowTooltip,
})
const OverflowTooltip = ({ component = Typography, classes: parentClasses, ...props }) => {
  const classes = useStyles(styles)()

  const [overflowActive, setOverflowActive] = useState(false)

  const ref = useRef()
  const { placement = 'bottom-start', followCursor = true, children, label = children, ...childProps } = props

  const setOverflowState = () => {
    _isElementOverflowing(ref.current)
      ? setOverflowActive(true)
      : setOverflowActive(false)
  }

  useEffect(() => {
    setOverflowState()
    window.addEventListener('resize', setOverflowActive)

    return () => {
      window.removeEventListener('resize', setOverflowActive)
    }
  }, [])

  return (
    <Tooltip
      title={label}
      placement={placement}
      className={classes.root}
      followCursor={followCursor}
      disableHoverListener={!overflowActive}
    >
      <Box
        ref={ref}
        component={component}
        className={`${classes.label} ${parentClasses?.label ? parentClasses.label : ''}`}
        {...childProps}
      >
        {children}
      </Box>
    </Tooltip>
  )
}

export default OverflowTooltip
