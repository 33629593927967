import { preload, useMemoRef, useStyles } from '@platform/react/hook'
import { Grid } from '@mui/material'
import SimpleCard from 'ui/Component/Card/Simple'
import ErrorBoundary from 'ui/Error'
import { Suspense } from 'react'

/**
 * Helper function to render the list of cards
 *
 * @param {Object} value              value for the card
 * @param {number} width              width of the card
 * @param {Object} icon               icon options
 * @param {string} background         background color to use
 * @param {React.ReactNode} Template  component to render the card
 * @returns {*[]|*}
 * @private
 */
const _content = ({ value, width, icon, background, Template = SimpleCard }) => (
  !value?.length ? [] : value.map((card, i) => (
    <Template
      key={i}
      xs={12}
      sm={width}
      md={width}
      lg={width}
      xl={width}
      icon={icon}
      background={background}
      label={card?.name || '-'}
      subLabel={card?.group || '-'}
    />
  ))
)

const styles = theme => ({
  label: {
    fontWeight: 500,
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(theme.typography.fontSize),
  },
})

/**
 * Card List Component
 *
 * Displays a list of {@link SimpleCard} or {@link SmallCard} components
 *
 * @param {Object[]} value            value of each item
 * @param {string} template           card component to use
 * @param {Object} props              additional props
 * @param {number} [props.cardWidth]  the width (in terms of grid spacing) for each card
 * @returns {JSX.Element}
 * @constructor
 */
const CardList = ({ value, template, ...props }) => {
  const classes = useStyles(styles)()

  // Filter out objects with same 'name' property
  const filteredValue = [...new Map(value.map(item => [item.name, item])).values()]

  const { xs, sm, md, lg, xl, label, ...options } = props
  const { cardWidth: width = 'auto', icon, background } = options

  const Template = preload(template)

  return (
    <ErrorBoundary>
      <Suspense fallback={null}>
        <Grid
          item
          container
          xs={xs}
          sm={sm}
          md={md}
          lg={lg}
          xl={xl}
        >
          <Grid
            item
            xs={12}
            className={classes.label}
          >
            {label}
          </Grid>
          <Grid
            item
            container
            xs={12}
          >
            {_content({ value: filteredValue,
              width,
              icon,
              background,
              Template })}
          </Grid>
        </Grid>
      </Suspense>
    </ErrorBoundary>
  )
}

export default useMemoRef(CardList, props => [props.value])
