import React, { forwardRef, useImperativeHandle, useState } from 'react'
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton, Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { CloseOutlined } from '@mui/icons-material'
import { Consumer } from '@platform/react/context/application'
import { getThemeColor } from 'lib/util'

export const styles = theme => ({
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  close: {
  },
  actions: {
    padding: theme.spacing(2),
  },
  ...theme.custom.dialog,
})

/**
 * Mapping between breakpoint keywords (like {@code xs}) and actual dimensions
 * in {@code rem}s.
 *
 * @type {{xl: string, md: string, sm: string, xs: string, lg: string}}
 * @private
 */
const dimensions = {
  xs: '20',
  sm: '35',
  md: '50',
  lg: '70',
  xl: '90',
}

const Dialog = forwardRef((props, ref) => {
  const [state, setState] = useState({ open: false })

  useImperativeHandle(ref, () => ({
    setState: (newState) => {
      const [content] = newState.children || []
      const { props: childProps } = content || {}
      const {
        topDivider = true,
        bottomDivider = false,
        fullScreen = false,
        rigid = false, // will reset DialogContent's padding
        showHeader = true,
        height = null,
        width = null,
        background = null,
      } = childProps || {}

      setState({
        ...state,
        ...newState,
        content,
        childProps,
        topDivider,
        bottomDivider,
        fullScreen,
        showHeader,
        height,
        width,
        rigid,
        background,
      })
    },
    state,
  }))

  const { children } = state || {}
  const [contentRoot] = children || []
  const { actions } = contentRoot?.props || {}

  return (
    <Consumer>
      {({ router }) => {
        const {
          content,
          childProps = {},
          topDivider,
          bottomDivider,
          fullScreen,
          showHeader,
          height,
          width,
          rigid,
          background,
        } = state
        const { classes } = props
        const { restore } = router
        const { events } = childProps
        const { onClose } = events || {}

        return (
          <MuiDialog
            open={state.open}
            fullWidth
            fullScreen={fullScreen}
            maxWidth={childProps.maxWidth || 'lg'}
            // not having an onClose handler,
            // disables the ability to close dialog by clicking outside of it.
            onClose={ onClose || restore }
            PaperProps={{
              sx: {
                ...fullScreen && { borderRadius: '0!important' },
                ...height && { height: `calc(${dimensions[height]}vh)` },
                ...background && { backgroundColor: theme => getThemeColor(theme, background) },
                ...width && {
                  width: `calc(${dimensions[width]}vw)`,
                  maxWidth: '100%',
                },
              },
            }}
          >
            {state.open && (
              <>
                {!showHeader ? null : (
                  <DialogTitle className={classes.title}>
                    <Typography variant={'16/bold'}>
                      {state.title || childProps.title || ''}
                    </Typography>
                    {onClose || restore ? (
                      <IconButton
                        aria-label="close"
                        onClick={onClose || restore}
                        sx={{
                          margin: -1.75,
                          color: 'common.black',
                        }}
                      >
                        <CloseOutlined/>
                      </IconButton>
                    ) : null}
                  </DialogTitle>
                )}
                <DialogContent
                  dividers
                  sx={{
                    // can't do borderTop: '1px solid divider'
                    borderTop: topDivider ? 1 : 'none',
                    borderTopColor: topDivider ? 'divider' : 'none',
                    borderBottom: bottomDivider ? 1 : 'none',
                    borderBottomColor: bottomDivider ? 'divider' : 'none',
                    paddingBottom: 1.5,
                    ...rigid && { padding: 0 },
                  }}
                >
                  {!childProps.text ? null : (
                    <DialogContentText>
                      {childProps.text}
                    </DialogContentText>
                  )}
                  {content}
                </DialogContent>
                {actions && (
                  <DialogActions
                    className={classes.actions}
                    sx={{
                      justifyContent: 'center',
                      paddingTop: 1.5,
                    }}
                  >
                    {actions}
                  </DialogActions>
                )}
              </>
            )}
          </MuiDialog>
        )
      }}
    </Consumer>
  )
})

export default withStyles(styles)(Dialog)
