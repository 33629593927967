/* global React */
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog } from '@mui/material'
import { useStyles } from '@platform/react/hook'

/**
 * Styles for the dialog
 *
 * @param {Object} theme             the current theme
 * @param {boolean} [elevated=false] whether the dialog should be displayed above the loader
 * @return {*&{text: {"& ul": {margin: number, lineHeight: number}, whiteSpace: string}}}
 */
export const styles = (theme, { elevated }) => ({
  root: {
    ...elevated && {
      zIndex: theme.zIndex.modal + 2,
    },
  },
  text: {
    whiteSpace: 'pre-wrap',
    '& ul': {
      margin: 0,
      lineHeight: 1,
    },
  },
  actions: {
    padding: theme.spacing(2),
  },
  ...theme.custom.alert,
})

/**
 * Simple Alert Dialog
 *
 * @param {Object} props  props for the alert
 * @param {Object} ref    reference to the parent object
 * @return {JSX.Element}
 * @constructor
 */
const Alert = (props, ref) => {
  const classes = useStyles(styles, { elevated: props.elevated || false })()
  return (
    <Dialog
      fullWidth
      ref={ref}
      open={props.open}
      className={classes.root}
      maxWidth={props.maxWidth || 'xs'}
      onClose={props.events && props.events.onClose}
    >
      <DialogTitle>
        {props.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          component={'div'}
          className={classes.text}
        >
          {props.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        className={classes.actions}
      >
        {props.children}
      </DialogActions>
    </Dialog>
  )
}

export default Alert
