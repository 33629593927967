/* global G */
import React, { lazy, Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  lighten,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'
import ErrorBoundary from 'ui/Error'
import ScrollGrid from 'ui/Component/Grid/ScrollGrid'
import ChildrenHOC from '@platform/react/hoc/children'
import SvgIcon from 'ui/Element/Icon/Svg'
import { CloseOutlined, Link as LinkIcon, Fullscreen as FullscreenIcon, FullscreenExit as FullscreenExitIcon } from '@mui/icons-material'
import Simple from 'ui/Element/Button/Simple'
import ApplicationContext from '@platform/react/context/application'
import { PlatformEvent } from 'lib/util'
import SimpleImage from 'ui/Element/Image/Simple'

const styles = (theme, { shouldFullScreen = false }) => ({
  table: {
    '& .MuiTableRow-root:hover': {
      backgroundColor: theme.palette.background.content,
    },
  },
  tableHead: {
    '& .MuiTableCell-root': {
      fontSize: '0.75rem', // 12 px
      fontWeight: 500,
      letterSpacing: 'normal',
      color: theme.palette.text.secondary,
      borderRight: `1px solid ${lighten(theme.palette.border.main, 0.5)}`,
      borderBottom: `1px solid ${lighten(theme.palette.border.main, 0.5)}`,
      padding: theme.spacing(1),
    },
    '& :last-child': {
      borderRight: 'none',
    },
    whiteSpace: 'nowrap',
  },
  selectedRow: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    height: '70px',
  },
  breadcrumbs: {
    flexBasis: 0,
  },
  titleContainer: {
    flexBasis: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    lineHeight: 1,
    color: theme.palette.common.black,
  },
  descriptionBox: {
    [theme.breakpoints.up('md')]: {
      minWidth: '31.25rem', // 500px
      minHeight: '18.75rem', // 300px
    },
  },
  descriptionTitle: {
    height: '3.75rem',
    borderBottom: `1px solid ${theme.palette.gray[900]}`,
    padding: [['0.75rem', '1rem']],
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  descriptionContent: {
    ...shouldFullScreen && { overflow: 'hidden' },
    padding: [['1.5rem', '1rem'], '!important'],
  },
  bigImage: {
    position: 'absolute',
  },
  partImgContainer: {
    marginTop: shouldFullScreen ? 0 : '1.25rem',
    borderRadius: '0.75rem',
    overflow: 'hidden',
    height: shouldFullScreen ? 'calc(100vh - 11rem)' : '20rem',
    maxWidth: '100%',
    '& > div > img': {
      cursor: 'pointer',
      objectFit: shouldFullScreen ? 'contain' : 'fill',
    },
  },
  dialogButton: {
    textTransform: 'uppercase',
  },
  headCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  articleCell: {
    width: '7rem',
  },
  ...theme.custom.partsList,
})

/**
 * Compares {@param a} to {@param b} and sorts them first alphabetically, then by number, like:
 * A, B, C, 1, 5, 13, ...
 *
 * @param {Object} a first word to compare
 * @param {Object} b second word to compare
 * @returns {number}
 */
const comparator = (a, b) => {
  const wordA = /^[a-zA-Z]+$/.test(a.pos)
  const wordB = /^[a-zA-Z]+$/.test(b.pos)

  if (wordA && wordB) return a.pos.localeCompare(b.pos)
  if (wordA) return -1
  if (wordB) return 1

  const numA = !Number.isNaN(parseInt(a.pos, 10))
  const numB = !Number.isNaN(parseInt(b.pos, 10))

  if (numA && numB) return Number(a.pos) - Number(b.pos)
  if (numA) return -1
  if (numB) return 1

  return 0
}

/**
 * @typedef {import('@mui/material').ModalProps} ModalProps
 */

/**
 * Modal showing the description of a node (part or assembly).
 *
 * @param {Object} descBoxProps                        props for the component
 * @param {Object} descBoxProps.node                   the entity to display information about
 * @param {boolean} descBoxProps.open                  whether the modal is visible or not
 * @param {Object} descBoxProps.events                 events for the modal
 * @param {Object} descBoxProps.props                  additional props
 * @returns {null|JSX.Element}
 * @constructor
 */
const DescriptionBox = ({ node, open, events, ...props }) => {
  const theme = useTheme()
  const { labels, icons } = props
  const { close, addToCart, partInformation, minimize } = labels
  const { onClose, onDescriptionOpen, onAddPart } = events
  const [attachment, setAttachment] = useState({ value: { name: '' } })
  const [imageState, setImageState] = useState({})
  const [showImage, setShowImage] = useState(false)

  const canFullScreen = useMemo(() => imageState?.state?.src, [imageState])
  const shouldFullScreen = useMemo(() => imageState?.state?.progress === 100 && showImage, [imageState, showImage])

  const classes = useStyles(styles, { shouldFullScreen })()

  const articleNode = useMemo(() => (node?.articleId ? node : null), [node])

  const handleAddPart = (event) => {
    const platformEvent = new PlatformEvent(event, { node })
    onAddPart?.(platformEvent)
  }

  const maximizeIcon = icons?.maximizeIcon
    ? <SvgIcon
      width={'1rem'}
      height={'1rem'}
      icon={icons.maximizeIcon.name}
      variant={icons.maximizeIcon.variant}
    />
    : <FullscreenIcon />

  const minimizeIcon = icons?.minimizeIcon
    ? <SvgIcon
      width={'1rem'}
      height={'1rem'}
      icon={icons.minimizeIcon.name}
      variant={icons.minimizeIcon.variant}
    />
    : <FullscreenExitIcon />

  useEffect(() => {
    (async () => {
      setShowImage(false)
      if (open && articleNode) {
        const event = new PlatformEvent('open', { node: articleNode })
        const result = await onDescriptionOpen?.(event)
        setAttachment(result)
      }
    })()
  }, [open, articleNode])

  const handleImageClick = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    if (imageState?.state?.src) {
      setShowImage(prevState => !prevState)
    }
  }, [imageState])

  return !node ? null : (
    <Dialog
      open={open}
      fullScreen={shouldFullScreen}
      onClose={onClose}
      classes={{ paper: classes.descriptionBox }}
    >
      <DialogTitle className={classes.descriptionTitle} component={'div'}>
        <Typography variant={'14/bold'} sx={{ marginRight: 'auto' }}>
          {!shouldFullScreen
            ? partInformation
            : `${node?.name}, ${node?.description?.join(', ')}`
          }
        </Typography>
        {canFullScreen && (
          <Box onClick={handleImageClick} sx={{ display: 'flex', padding: '0.5rem', cursor: 'pointer' }}>
            {shouldFullScreen ? minimizeIcon : maximizeIcon}
          </Box>
        )}
        <IconButton
          onClick={onClose}
          sx={{ color: theme.palette.black.main }}
        >
          <CloseOutlined/>
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.descriptionContent}>
        {!shouldFullScreen && (
          <>
            <Typography
              variant={'18/bold'}
              color={theme.palette.black.main}
            >
              {node.name}
            </Typography>
            {/** @type string[] */
              (node?.description || []).map((x, i) => (
                <Typography
                  key={i}
                  variant={'14/medium'}
                  color={theme.palette.gray[400]}
                >
                  {x}
                </Typography>
              ))
            }
          </>
        )}
        <Box
          className={classes.partImgContainer}
          {...canFullScreen && { onClick: handleImageClick }}
        >
          <SimpleImage
            stretch={true}
            ration={'square'}
            events={{ onState: setImageState }}
            skipSkeleton={true}
            fallback={{
              size: '6.25rem',
              icon: 'part',
              variant: 'outlined',
              background: theme.palette.gray[960],
            }}
            attachment={attachment}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Simple
          variant={'text'}
          color={'primary'}
          fullWidth={false}
          onClick={onClose}
          value={close}
        />
        {shouldFullScreen && (
          <Simple
            variant={'contained'}
            color={'gray'}
            fullWidth={false}
            onClick={() => setShowImage(false)}
            value={minimize}
          />
        )}
        <Simple
          color={'primary'}
          variant={'contained'}
          fullWidth={false}
          onClick={handleAddPart}
          value={addToCart}
        />
      </DialogActions>
    </Dialog>
  )
}

/**
 * Render the contents of the current node.
 *
 * @param {Object} node                             the current node to render.
 * @param {Object} events                           events passed from parent component
 * @param {Object} contentProps                     additional props for the component
 * @param {Object} contentProps.classes             styles to apply
 * @param {() => JSX.Element} contentProps.listItem component to use for rendering sub folders
 * @param {Object} contentProps.options             options passed from parent component
 * @returns {JSX.Element}
 * @private
 */
const _content = (node, events, { classes, listItem: ListItem, ...options }) => {
  const { onNodeType } = events
  const nodeType = onNodeType(node?.type)

  const { siblings, multiSelection, labels } = options
  const siblingsIds = siblings.map(x => x.id)

  const displayedNode = (nodeType === 'Directory' && node)
  || (nodeType === 'File' && node.parent)

  const sortedChildren = displayedNode ? [...displayedNode.$children].sort(comparator) : []

  return (
    <>
      {!displayedNode ? null : sortedChildren.map((item, index) => (
        <ListItem
          key={index}
          node={item}
          events={events}
          multiSelection={multiSelection}
          labels={labels}
          isSelected={node.id === item.id || siblingsIds.includes(item.id)}
          {...{
            orderIcon: options.orderIcon,
            forwardIcon: options.forwardIcon,
            descriptionIcon: options.descriptionIcon,
            documentationIcon: options.documentationIcon,
            copyIcon: options.copyIcon,
            partIcon: options.partIcon,
            assemblyIcon: options.assemblyIcon,
          }}
        />
      ))
      }
    </>
  )
}

/**
 * Helper function to render the table header cells.
 *
 * @param {Object} headerProps                    props for the component
 * @param {string[]} headerProps.labels           labels for the header cells
 * @param {Object} headerProps.node               the currently selected node
 * @param {boolean} headerProps.multiSelection    whether multi selection mode is active
 * @param {string[]} headerProps.selectedItems    list of selected (clicked) order numbers
 * @param {string} headerProps.copyMultipleLabel  list of selected (clicked) order numbers
 * @param {Object} headerProps.events             events for the header cells
 * @param {Object} headerProps.classes            styling for the header cells
 * @param {Object} headerProps.options            additional options for the header cells
 * @returns {*}
 * @private
 */
const _header = ({
  labels,
  node,
  multiSelection,
  selectedItems,
  copyMultipleLabel,
  events,
  classes,
  ...options
}) => labels.map((label, index) => {
  const { eventBus } = useContext(ApplicationContext)
  // const targetNode = node?.$children?.length ? node : node.parent
  // const hasLeafChildren = targetNode?.$children?.some?.(child => child.type !== 'Directory')

  const handleYank = async () => {
    await navigator.clipboard.writeText(selectedItems.join('\n'))

    eventBus.dispatch(eventBus.type(G.DATA, G.UPDATE), {
      message: copyMultipleLabel,
      severity: 'success',
      variant: 'filled',
      duration: 3000,
      color: 'primary',
      close: false,
    })
  }

  // const showCheckbox = (index === 1 && hasLeafChildren)
  //     || false
  // const showCopyIcon = (index === 3 && hasLeafChildren && selectedItems.length)
  //     || false
  // NOTE: Temporary: SP-1469
  const showCheckbox = false
  const showCopyIcon = false
  const shouldFlex = showCheckbox || showCopyIcon || false

  const copyIcon = options?.copyIcon
    ? <SvgIcon
      width={'1rem'}
      height={'1rem'}
      icon={options.copyIcon.name}
      variant={options.copyIcon.variant}
    />
    : <LinkIcon/>

  return (
    <TableCell
      key={index}
      className={
        `${shouldFlex ? classes.headCell : ''} ${showCheckbox ? classes.articleCell : ''}`
      }
    >
      {label}
      {!showCheckbox ? null : (
        <Checkbox
          size={'small'}
          sx={{ padding: 0 }}
          checked={multiSelection}
          onChange={e => events?.onChange?.(e.target.checked)}
        />
      )}
      {!showCopyIcon ? null : (
        <Box onClick={handleYank} sx={{ cursor: 'pointer' }}>
          {copyIcon}
        </Box>
      )}
    </TableCell>
  )
})

/**
 * Component used to display a parts list for the current {@param node} in a table. A parts list
 * consists of the {@param node}s {@code $children}.
 *
 * @param {Object} listProps                          props for the component
 * @param {React.Ref<Object>} listProps.forwardedRef  ref to the element
 * @param {Object} listProps.node                     the current node to render.
 * @param {string[]} listProps.siblings               list of sibling node ids. Siblings are nodes
 *                                                    that refer to the same {@code pos} within a
 *                                                    parts list
 * @param {Object} listProps.classes                  classes from the parent component
 * @param {Object} listProps.events                   events passed from parent component
 * @param {Function} listProps.events.onClick         handler for clicking on an item.
 * @param {Function} [listProps.events.onOpen]        optional onOpen handler.
 * @param {Object} [listProps.parentClasses]          optional classes to overwrite the styles
 * @param {String} listProps.template                 template to use for rendering children
 * @param {Object} listProps.props                    additional props from parent component
 * @returns {JSX.Element}
 * @constructor
 */
const PartsList = ({
  forwardedRef,
  node,
  siblings,
  events,
  classes: parentClasses,
  template,
  ...props
}) => {
  const [clickedNode, setClickedNode] = useState(null)
  const [showDescription, setShowDescription] = useState(false)
  const [currentNode, setCurrentNode] = useState(node || {})

  const [multiSelection, setMultiSelection] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])

  const View = useMemo(() => lazy(() => import(`@ui/${template}`)), [template])

  const {
    spacing = 0,
    gap = 0,
    space = 0,
    labels = [],

    partInformationLabel,
    addToCartLabel,
    closeLabel,
    minimizeLabel,
    copyLabel,
    copyMultipleLabel,

    maximizeIcon,
    minimizeIcon,

    ...restProps
  } = props

  const theme = useTheme()
  const classes = useStyles(styles)()

  const descriptionHandler = (selectedNode) => {
    setShowDescription(true)
    setClickedNode(selectedNode)
  }

  const selectionHandler = (item) => {
    setSelectedItems(prevState => (prevState.includes(item)
      ? prevState.filter(x => x !== item)
      : [...prevState, item]))
  }

  const rowEvents = {
    ...events,
    onCheck: selectionHandler,
    onDescription: descriptionHandler,
  }

  const options = {
    siblings,
    multiSelection,
    labels: {
      addToCart: addToCartLabel,
      copy: copyLabel,
    },
    ...restProps,
    listItem: View,
  }

  // Set {@code currentNode} if onOpen event handler is present.
  useEffect(() => {
    (async () => {
      const newNode = await events?.onOpen?.(null)
      newNode && setCurrentNode(newNode)
    })()
  }, [])

  // Update the current node if changed from parent.
  useEffect(() => {
    setCurrentNode(node)

    // Always scroll the element into view in case it's not visible
    const element = document.getElementById(node?.id)
    element && element.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
  }, [node])

  // Reset the multi selection mode if we switch to a assmebly node, meaning we changed levels
  useEffect(() => {
    node?.type === 'Directory' && setMultiSelection(false)
  }, [node])

  // Reset the list of currently selected items if we get ouf of multiselection mode
  useEffect(() => {
    !multiSelection && setSelectedItems([])
  }, [multiSelection])

  return (
    <ErrorBoundary>
      <DescriptionBox
        node={clickedNode}
        open={showDescription}
        labels={{
          close: closeLabel,
          minimize: minimizeLabel,
          addToCart: addToCartLabel,
          partInformation: partInformationLabel,
        }}
        icons={{
          maximizeIcon,
          minimizeIcon,
        }}
        events={{
          onAddPart: events?.onAddPart,
          onDescriptionOpen: events?.onDescriptionOpen,
          onClose: () => setShowDescription(false),
        }}
      />
      <Suspense fallback={null}>
        <Grid
          item
          className={parentClasses.root}
          {...!props?.widthDelta && {
            xs: props.xs,
            sm: props.sm,
            md: props.md,
            lg: props.lg,
            xl: props.xl,
          }}
          style={{
            padding: spacing
              ? theme.spacing(spacing)
              : theme.spacing(space, gap),
          }}
        >
          <ScrollGrid
            ref={forwardedRef}
            className={parentClasses.partsList}
          >
            <TableContainer>
              <Table
                size={'small'}
                sx={{ wordBreak: 'break-all' }}
                className={classes.table}
              >
                <TableHead>
                  <TableRow className={classes.tableHead}>
                    {_header({
                      labels,
                      classes,
                      selectedItems,
                      multiSelection,
                      copyMultipleLabel,
                      node: currentNode || {},
                      copyIcon: options.copyIcon,
                      events: { onChange: setMultiSelection },
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_content(currentNode || {}, rowEvents, options)}
                </TableBody>
              </Table>
            </TableContainer>
          </ScrollGrid>
        </Grid>
      </Suspense>
    </ErrorBoundary>

  )
}

export default useMemoRef(ChildrenHOC(PartsList), props => [props.node, props.classes])
