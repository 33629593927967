/* global React */
import { useState, useEffect, useTransition, Suspense, Children, forwardRef } from 'react'
import { Badge, Grid, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PlatformEvent } from 'lib/util'
import { useMemoRef } from '@platform/react/hook'
import Section from 'ui/Component/Grid/Section'

const ForwardedRefSection = forwardRef(Section)

/**
 * Returns the TabsGroup component's current theme styles.
 *
 * @param {object} theme  the application's current theme
 * @returns {object}      the VerticalStep component's styles
 */
const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  verticalHeader: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  horizontalHeader: {
    marginBottom: 24,
    minHeight: 25,
  },
  tab: {
    alignItems: 'flex-start',
    padding: [['0.5rem', '1rem']],
    borderRadius: 50,
    color: theme.palette.common.black,
    marginRight: 12,
    '&:last-child': {
      marginRight: 0,
    },
    '&:hover': {
      backgroundColor: theme.palette.gray[900],
    },
    '&.Mui-selected': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.gray[950],
    },
    '&.MuiButtonBase-root': {
      minHeight: '10px',
    },
  },
  content: {
    height: '100%',
  },
  badge: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.signal.main,
  },
  ...theme.custom.tabsGroup,
}))

/**
 * Function to render the {@link Tabs}
 *
 * @param {Object[]} children       children to render
 * @param {Object} options          component options
 * @param {Object} options.classes  styles for the component
 * @returns {Array<Exclude<Promise<*>, boolean | null | undefined>>}
 * @private
 */
const _headers = (children, options) => Children.map(children, async (child, index) => {
  const { classes, horizontal } = options
  /**
   * Executing {@code onOpen} event handler for each tab
   */
  await child.props?.events?.onOpen?.(null)

  /**
   * Executing {@code getLabel} event handler
   */
  const {
    label,
    badge = false,
    count,
    hidden = false,
  } = await child.props?.events?.getLabel?.(null) || {}

  const tabLabelText = (
    <Typography
      variant={'14/semibold'}
    >
      {label || child.props.label}
    </Typography>
  )

  const tabLabel = badge
    ? <Badge
      classes={{ badge: classes.badge }}
      badgeContent={count}
      variant={!count ? 'dot' : 'standard'}
    >
      {tabLabelText}
    </Badge>
    : tabLabelText

  return hidden ? null : (
    <Tab
      key={index}
      className={classes.tab}
      value={child.props.id || index}
      label={tabLabel}
      style={{
        ...!horizontal && {
          marginBottom: 12,
        },
      }}
    />
  )
})

/**
 * Function to render the {@link TabPanel}
 *
 * @param {Object[]} children       children to render
 * @param {number} activeTab        index or id of active tab
 * @param {Object} options          component options
 * @param {Object} options.classes  styles for the component
 * @returns {Array<Exclude<unknown, boolean | null | undefined>>}
 * @private
 */
const _content = (children, activeTab, options) => {
  const { classes, theme, horizontal, tabWidth } = options
  return Children.map(
    children, (child, index) => activeTab === (child.props.id || index) && (
      <TabPanel
        key={index}
        tabWidth={tabWidth}
        horizontal={horizontal}
        className={classes.content}
        theme={theme}
        {...child.props}
      />
    ),
  )
}

/**
 * Tab Panel element.
 *
 * @param {object[]} children               the contents of the tab element
 * @param {string} className
 * @param {object} [props]                  additional element's properties
 * @param {string[]} [props.classes]
 * @returns {null|JSX.Element}
 * @constructor
 */
const TabPanel = ({ children, className, horizontal, ...props }) => (
  <ForwardedRefSection
    className={className}
    classes={props.classes}
    xs={horizontal ? 12 : true}
    paddingLeft={!horizontal && 2}
    spacing={props.spacing}
    space={props.space}
    gap={props.gap}
  >
    {children}
  </ForwardedRefSection>
)

/**
 * TabsGroup Component.
 *
 * Designed to represent each direct child with a tab element. Each child (tab) can have an id
 * prop set. A child element is displayed, whenever the {@param props.activeTab} has that child's
 * id as value.
 *
 * @param {object} forwardedRef             a reference object to the root element
 * @param {boolean} header                  {@code true} to display a tab button for each child. The
 *                                          button's text will be set from a label property in each
 *                                          child's options
 * @param {object[]} children               the children elements to be set as one tab each
 * @param {number|string} children.id       the value the {@param props.activeTab} must have for
 *                                          this element to be displayed
 * @param {Object[]} [children.events]      events for the tab
 * @param {object} [events]                 component's event handlers
 * @param {function} [events.onChange]      event handler executed whenever the active tab changes
 * @param {object} [props]                  additional component's properties
 * @param {number|string} [props.activeTab] the key of the currently active tab's element
 * @constructor
 */
const TabsGroup = ({ forwardedRef, header, children, events, ...props }) => {
  const { tabWidth = '11rem' /* 192px */, horizontal = true } = props
  const theme = useTheme()
  const classes = useStyles()
  const [, startTransition] = useTransition()
  const [firstChild] = children || [{}]
  const initialTab = props.activeTab || firstChild.props?.id || 0
  const [activeTab, setActiveTab] = useState(initialTab)
  const [headers, setHeaders] = useState([])

  const handleChange = (event, newValue) => {
    startTransition(() => setActiveTab(newValue))
    events?.onChange?.(new PlatformEvent(new CustomEvent('change'), { activeTab: newValue }))
  }

  useEffect(() => {
    startTransition(() => setActiveTab(props.activeTab || initialTab))
  }, [props.activeTab])

  useEffect(() => {
    (async () => {
      setHeaders(await Promise.all(_headers(children, { classes, horizontal })))
    })()
  }, [])

  const isMd = useMediaQuery(t => t.breakpoints.down('lg'))

  /**
   * If {@param header} is true, wait for {@code headers} to be fully fetched,
   * then render content, so that onOpen event handlers get executed before
   * content is rendered.
   *
   * Otherwise, render content immediately.
   */
  return !children.length ? null : (
    <Grid
      item
      container
      ref={forwardedRef}
      className={classes.root}
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      lg={props.lg}
      xl={props.xl}
    >
      {header && headers.length > 0 && (
        <Grid
          item
          container
          xs={12}
          {...horizontal || isMd
            ? { sm: 12 }
            : { sx: { width: tabWidth, flexBasis: 'auto!important' } }
          }
        >
          <Tabs
            value={activeTab}
            onChange={handleChange}
            orientation={isMd || horizontal ? 'horizontal' : 'vertical'}
            scrollButtons={'auto'}
            allowScrollButtonsMobile={true}
            variant={'scrollable'}
            className={
              horizontal
                ? classes.horizontalHeader
                : classes.verticalHeader
            }
            TabIndicatorProps={{ hidden: true }}
            sx={{ ...!horizontal && !isMd && { paddingRight: theme.spacing(2) } }}
          >
            {headers}
          </Tabs>
        </Grid>
      )}
      {(!header || headers.length > 0) && (
        <Suspense>
          {_content(children, activeTab, { classes, theme, horizontal: horizontal || isMd, tabWidth })}
        </Suspense>
      )}
    </Grid>
  )
}

export default useMemoRef(TabsGroup, props => [props.children, props.activeTab])
