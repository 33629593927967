import { Box, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import { forwardRef } from 'react'
import Chip from 'ui/Element/Text/Chip'

const styles = theme => ({
  label: {
    maxWidth: 'calc(100%)',
  },
  equipment: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

/**
 * Displays a documentation name.
 *
 * @param {object} classes  element's style classes
 * @param {object} props    element's properties
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ListemItemCellFavoriteDocumentationName = (classes, props = {}) => {
  const theme = useTheme()
  const { label, product } = props

  const ForwardedChip = forwardRef((chipProps, ref) => <Chip forwardedRef={ref} {...chipProps} />)
  const chips = (product || []).map((x, index) => (
    <ForwardedChip
      key={index}
      color={'info'}
      text={x}
    />
  )).reduce((result, item) => (result.length ? [result, ' ', item] : [item]), [])

  return (
    <Box className={classes.equipment}>
      <Box className={classes.label}>
        <OverflowTooltip
          variant={'14/bold'}
          classes={{ label: classes.row }}
          color={theme.palette.common.black}
        >
          {label}
        </OverflowTooltip>
        <OverflowTooltip
          component={'span'}
          variant={'12/regular'}
          color={theme.palette.gray[400]}
          classes={{ label: classes.row }}
        >
          {chips}
        </OverflowTooltip>
      </Box>
    </Box>
  )
}

export default CellHOC(ListemItemCellFavoriteDocumentationName, styles)
