/* eslint-disable no-nested-ternary */
import OptionHOC from '@platform/react/hoc/autocomplete/option'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import { Box, Typography, useTheme } from '@mui/material'
import AvatarImage from 'ui/Element/Image/Avatar'
import { getThemeColor } from 'lib/util'

const styles = theme => ({
  content: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
})

/**
 * Displays an Assembly for the {@link Autocomplete} component. Will display a top label consisting
 * of the type of part (part or assembly) and additionally where it's assembled in. Will also
 * display a sublabel showing its order number, if available.
 *
 * @param {Object} classes  styling for the option
 * @param {Object} props    props for the option
 *
 * @return {*}
 * @constructor
 */
const AssemblyOption = (classes, props = {}) => {
  const { option, labels, ...optionProps } = props || {}
  const theme = useTheme()

  const {
    parent = {},
    orderNumber,
    key = 'item',
    name,
    image = null,
    value,
  } = option || {}

  const { assemblyLabel, assembledInLabel } = labels || {}
  const { equipmentIcon = 'device_simple' } = optionProps

  const topLabel = [assemblyLabel, `${assembledInLabel} ${parent.name}`].join(', ')
  const subLabel = orderNumber || null
  const label = name || value || '-'

  const currentAttachment = image?.attachment?.value?.name
    ? { ...image.attachment, key: image?.value?.attachmentId || image.id, api: { ref: 'equipment' } }
    : { value: { name: '' } }

  return (
    <Box
      key={key}
      component={'div'}
      className={classes.content}
    >
      <Box sx={{ width: '40px', marginRight: '16px' }}>
        <AvatarImage
          attachment={currentAttachment}
          fallback={{
            icon: equipmentIcon,
            color: getThemeColor(theme, 'text.regular'),
            variant: 'outlined',
          }}
        />
      </Box>
      <Box className={classes.option}>
        {topLabel && (
          <OverflowTooltip classes={{ label: classes.label }}>
            {topLabel}
          </OverflowTooltip>
        )}
        <OverflowTooltip>
          {label}
        </OverflowTooltip>
        {subLabel && (
          <Typography className={classes.label}>
            {subLabel}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default OptionHOC(AssemblyOption, styles)
